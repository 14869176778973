import React, { useEffect, useState } from 'react'
import { AppDispatch, RootState } from '../../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { ViewIntersetExam, finishExam, masterLov, submitAnswer } from '../Utils/slice'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'
import SkillAssessmentFrom from './SkillAssessmentCommon/SkillAssessmentFrom'
import dayjs from 'dayjs'

/**
 * Renders the component for pending Skill Portrait Exam.
 *
 * @return {JSX.Element} The rendered component.
 */
const SkillAssessmentPending = () => {
    const dispatch: AppDispatch = useDispatch()
    const { id }: any = useParams()
    const allsteps = useSelector((state: RootState) => state.WEBPROFILE.bothExamData)
    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const navigate = useNavigate()
    // const loading = useSelector((state: RootState) => state.WEBPROFILE.appLoading)
    // const [totalSeconds, setTotalSeconds] = useState<any>(0);
    // const [isActive, setIsActive] = useState(true);
    const [loading, setLoading] = useState(true)
    // const [formatedTime, setFormatedTime] = useState<any>()
    // const currentDate = dayjs().format('YYYY-MM-DD')
    const [btnLoading, setBtnLoading] = useState(false);
    const decodedDataId = atob(id);
    const [allMaster, setAllMaster] = useState()


    useEffect(() => {
        
        // Api to be called when the component mounts
        const fetchData = async () => {
            try {
                const data = await dispatch(ViewIntersetExam(decodedDataId));

                const tempArray: any = {};
                const uniqueMasterTypes = Array.from(new Set(data.data.questions.map((item: any) => item.type === 2 && item.master_type)));

                if (uniqueMasterTypes.length > 0) {
                    for (const item of uniqueMasterTypes) {
                        if (typeof item === "string") {
                            try {
                                const result = await dispatch(masterLov(item));
                                tempArray[item] = result.data;
                            } catch (error) {
                                error
                            }
                        }
                    }
                }
                setAllMaster(tempArray)

                if (data?.data?.attends?.length > 0) {
                    const questions = data.data.questions;
                    const attendsQue = data.data.attends;
                    const findWithoutAnswer = questions?.filter((item: any) => !attendsQue.some((item2: any) => item.id === item2.question_id));
                    const findIndex = questions?.findIndex((item: any) => item?.id === findWithoutAnswer?.[0]?.id);
                    data.data.attends.map((item: any) => form.setFieldValue(item.question_id, parseInt(item?.answer)));
                    setCurrent(findIndex);
                }

                setLoading(false);
            } catch (error) {
                error
            }
        };

        fetchData();
    }, []);


    const steps = allsteps?.questions?.map((item: any, index: number) => ({
        key: item?.id,
        title: item?.question,
        content: item?.answers,
        question_id: item?.id,
        options: item?.type,
        master_type: item?.master_type,
        index: index + 1,
        description:item?.description
    }));
    const itemsPerPage = 6;
    const startIndex = current;
    const endIndex = current + itemsPerPage;
    const getFirstSix = steps?.slice(startIndex, endIndex);
    const lastArrayData = getFirstSix && getFirstSix[getFirstSix?.length - 1]; // for identity done btn or next btn


    // useEffect(() => {
    //     if (steps?.length > 0 && allsteps) {
    //         const ans = allsteps?.attends?.find((item: any) => {
    //             return (
    //                 item?.question_id === steps[current]?.question_id
    //             )
    //         })

    //         if (ans !== undefined) {
    //             if (steps[current].options === 1) {
    //                 form.setFieldValue(`answer${steps[current].question_id}`, parseInt(ans?.answer))
    //             } else if (steps[current].options === 2) {
    //                 form.setFieldValue(`answer${steps[current].question_id}`, ans?.answer)
    //             } else if (steps[current].options === 3) {
    //                 form.setFieldValue(`answer${steps[current].question_id}`, ans?.answer)
    //                 setShowFile(ans?.answer)
    //                 setFileList([
    //                     {
    //                         uid: "-1",
    //                         status: "done",
    //                         url: WebConstant?.IMAGE_URL + ans?.answer,
    //                         thumbUrl: WebConstant?.IMAGE_URL + ans?.answer,
    //                         name: ans?.answer ? ans?.answer.substring(ans?.answer.lastIndexOf('/') + 1) : null,
    //                     },
    //                 ]);
    //             }
    //         }
    //     }
    // }, [current, allsteps])
    // const onFocus = () => {
    //     setIsActive(true);
    // };
    // const onBlur = () => {
    //     setIsActive(false);
    // }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (isActive) {
    //             setTotalSeconds((prevTotalSeconds: any) => prevTotalSeconds + 1);
    //         }
    //     }, 1000);


    //     window.addEventListener("focus", onFocus);
    //     window.addEventListener("blur", onBlur);
    //     return () => {
    //         clearInterval(interval);
    //         window.removeEventListener("focus", onFocus);
    //         window.removeEventListener("blur", onBlur);
    //     };
    // }, [isActive]);



    // this function for incress hours and min upone second
    // const formatTime = () => {
    //     const hours = Math.floor(parseInt(totalSeconds) / 3600);
    //     const minutes = Math.floor((parseInt(totalSeconds) % 3600) / 60);
    //     const remainingSeconds = parseInt(totalSeconds) % 60;

    //     const hoursStr = String(hours).padStart(2, '0');
    //     const minutesStr = String(minutes).padStart(2, '0');
    //     const secondsStr = String(remainingSeconds).padStart(2, '0');
    //     setFormatedTime(`${hoursStr}:${minutesStr}:${secondsStr}`)
    //     return `${hoursStr}:${minutesStr}:${secondsStr}`;
    // };

    // Function to be called when user has clicked on next button.
    const nextButton = (data: any) => {
        // const formattedDateTime = `${currentDate} ${formatedTime}`;
        setBtnLoading(true)
        const newArray = [];
        for (const [ques_id, answer] of Object.entries(data)) {
            const findQuestionType = getFirstSix.find((item: any) => item.question_id === Number(ques_id))
            newArray.push({ ques_id, question_type: findQuestionType.options, answer });
        }
        data.answers = newArray
        data.assessment_type = "1"
        const keysToRemove = getFirstSix.map((item: any) => item.question_id);
        keysToRemove.forEach((key: any) => {
            delete data[key];
            return data
        });

        if (steps?.length === lastArrayData?.index) {
            dispatch(submitAnswer(decodedDataId, data)).then(() => {
                completeExam(data)
            }).catch((e: any) => {
                e
                setBtnLoading(false)
            })
        } else {
            dispatch(submitAnswer(decodedDataId, data)).then(() => {
                setBtnLoading(false)
                setCurrent(current + itemsPerPage);
            })
                .catch((e: any) => {
                    e
                    setBtnLoading(false)
                })
        }
        // if (steps[current].options === 3) {

        //     // data.current_time = formattedDateTime,
        //         data.question_type = "3"
        //     data.question_id = steps[current].question_id
        //     delete data[`answer${steps[current].question_id}`]

        //     const formData = new FormData()

        //     for (const file in data) {
        //         formData.append(file, data[file]);
        //         // formData.append(field, data[field] ? data[field] : null);
        //     }
        //     dispatch(submitAnswer(id, formData))
        //         .then(() => {
        //             dispatch(ViewIntersetExam(id))
        //             if (current + 1 === steps.length) {
        //                 completeExam(data)
        //             } else {
        //                 setCurrent(current + 1)
        //             }
        //         })
        //         .catch((e: any) => e)
        // } else {

        //     data.answer = form.getFieldValue(`answer${steps[current].question_id}`),
        //         // data.current_time = formattedDateTime,
        //         data.question_type = steps[current].options
        //     data.question_id = steps[current].question_id
        //     delete data[`answer${steps[current].question_id}`]

        //     if (current + 1 === steps.length) {
        //         dispatch(submitAnswer(id, data))
        //             .then(() => {
        //                 completeExam(data)
        //                 dispatch(ViewIntersetExam(id))
        //             })
        //             .catch((e: any) => e)
        //     } else {
        //         dispatch(submitAnswer(id, data)).then(() => {
        //             setCurrent(current + 1)
        //             dispatch(ViewIntersetExam(id))

        //         }).catch((e: any) => e)
        //     }

        // }
    }
    
    //scroll to top on next button click
    useEffect(()=>{ 
        if(!btnLoading){
        if ((document.body.clientHeight-4) > window.innerHeight) {
                       window.scrollTo({ top: 0, behavior: "smooth" });
                   }}
       },[nextButton,btnLoading])

  // Function to be called when user has answered all questions
    const completeExam = (data: any) => {
        data.current_time = dayjs().format('YYYY-MM-DD HH:mm:ss'),
            dispatch(finishExam(decodedDataId,data)).then(() => {
                navigate("/report")                
                setBtnLoading(false)
            })
                .catch((e: any) => {
                    e
                    setBtnLoading(false)
                })

    }

    // Function to be called when user has clicked on previous button to show previous 6 questions
    const prev = () => {
        setCurrent(current - 1)

    }

    // validate form fields after clicking on next button
    const validateForm = () => {
        form.validateFields()
        // validateFields(form, setDisabled);
        // disabled;
    };
    return !loading && allsteps ? (
        <SkillAssessmentFrom
            steps={steps}
            nextButton={nextButton}
            form={form}
            current={current}
            setCurrent={setCurrent}
            // formatTime={formatTime}
            prev={prev}
            getFirstSix={getFirstSix}
            lastArrayData={lastArrayData}
            validateForm={validateForm}
            btnLoading={btnLoading}
            type="pending"
            allMaster={allMaster}

        />
    ) : <PageSpinner />
}

export default SkillAssessmentPending