import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../../../../CommonComponent/BreadcrumbComponent";
import { SkillAssessmentBreadcrumb } from "../../../../../../config/BreadcrumbConfig";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { Button, Col, Form, Progress, Radio, Row, Tooltip } from "antd";
import { rules } from "./Rules";
import {
  InfoCircleOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/app";


/**
 * Renders common form component for skill portrait Exam.
 *
 * @param {object} props - The props object containing the following properties:
 *   - nextButton: A function to handle the next button click.
 *   - current: The current index of the question being displayed.
 *   - steps: An array of questions.
 *   - btnLoading: A boolean indicating if the button is in a loading state.
 *   - setCurrent: A function to set the current index.
 *   - isView: A boolean indicating if the form is in view mode.
 *   - lastArrayData: The last array data.
 *   - allMaster: An object containing master data.
 * @return {JSX.Element} The rendered form.
 */
const SkillAssessmentFrom = (props: any) => {
  
  // showFile form props
  const {
    nextButton,
    current,
    steps,
    btnLoading,
    setCurrent,
    isView,
    lastArrayData,
    allMaster,
  } = props;

  const [options, setOption] = useState<any>();
  const initialStoreData: any = useSelector(
    (state: RootState) => state.AUTH.initialData
  );


  useEffect(() => {
    const initialData: any = sessionStorage.getItem("initialData");
    const initialDataJson = JSON.parse(initialData);
    if (initialDataJson) {
      setOption(initialDataJson?.experience_levels);
    } else {
      setOption(initialStoreData?.experience_levels);
    }
  }, []);

  // Renders the appropriate answer input component based on the question type.
  const allInput: any = (data: any, indexAllQue: any) => {
    switch (data.options) {
      case 1:
        return (
          <InputBox.Select
            placeholder="Answer"
            name={data?.question_id}
            disabled={isView ?? false}
            required={!isView ? true : false}
            // rules={rules.textAnswer()}
            rules={isView ? [] : rules.answer()}
            options={{
              list: [
                { id: 1, name: "Yes" },
                { id: 2, name: "No" },
              ],
              valueKey: "id",
              textKey: "name",
            }}
          />
        );

      case 2:
        return (
          <InputBox.Select
            placeholder="Answer"
            name={data?.question_id}
            disabled={isView ?? false}
            required={!isView ? true : false}
            // rules={rules.textAnswer()}
            rules={isView ? [] : rules.answer()}
            // onFocus={() => type !== "pending" && getMasterData(data.master_type)}
            allowClear
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={{
              list: allMaster[data.master_type],
              valueKey: "id",
              textKey: "name",
            }}
          />
        );

      case 3:
        return (
          <Form.Item
            name={data?.question_id}
            rules={props.type !== "view" ? rules.answer() : []}
          >
            <Radio.Group disabled={isView ?? false}>
              <div
                className={`${
                  indexAllQue === 0
                    ? "radioGrpExamIconShowTitle"
                    : "radioGrpExamIconHideTitle"
                } radioGrpExamIcon radioGrpExamIconShowTitle `}
              >
                {options?.map((contentItem: any, index: number) => {
                  return (
                    <Tooltip
                      placement="top"
                      title={contentItem?.name}
                      key={index}
                      overlayClassName="examTooltip"
                    >
                      <Radio value={contentItem.id}>
                        <span className={"title"}>{contentItem?.name}</span>
                      </Radio>
                    </Tooltip>
                  );
                })}
              </div>
            </Radio.Group>
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            name={data?.question_id}
            rules={props.type !== "view" ? rules.answer() : []}
          >
            <Radio.Group>
              <div className="radioGrpExamIcon">
                {options?.map((contentItem: any, index: number) => {
                  return (
                    <Tooltip
                      placement="topLeft"
                      title={contentItem?.title}
                      key={index}
                    >
                      <Radio value={contentItem.id} />
                    </Tooltip>
                  );
                })}
              </div>
            </Radio.Group>
          </Form.Item>
        );
    }
  };

  return (
    <div className="examModal">
      <div className="container">
        <div className="breadcrumb">
          <BreadcrumbComponent key={1} items={SkillAssessmentBreadcrumb.path} />
        </div>

        <div className="interesQnaSection skillSection">
          <div className="totalAns">
            <div className="totalAnsMain">
              <div className="allAns">
                <span className="currentAns">
                  {props?.getFirstSix[0]?.index} - {lastArrayData?.index}{" "}
                </span>{" "}
                / <span className="totalLength">{steps?.length}</span>
              </div>
            </div>
          </div>
          <div className="ansListRadiobtn">
            <FormBox
              layout="vertical"
              autoComplete="off"
              form={props.form}
              id="skillForm"
              onFinish={nextButton}
              className="userProfile commanDrawer"
              // onChange={validateForm}
            >
              <h6>Hover over skill for more detailed description.</h6>

              {isView ? (
                <Progress percent={100} strokeColor="#1DAB3B" />
              ) : (
                <Progress
                  percent={Math.round((current / steps.length) * 100)}
                  strokeColor="#1DAB3B"
                />
              )}
              {props.getFirstSix.map((item: any, index: any) => {
                return (
                  <Row key={index} className="radioGrpExam">
                    <Col xs={24} sm={24} md={12} xl={16} className="questions">
                      <div className="step_content">
                        {" "}
                        <span className="currentAns">Q.{item.index}</span>
                        {item?.description ? (
                          <>
                            <span className="currentQuestion">
                              {item?.title}
                            </span>
                            {/* For showing tooltip for description about questions  */}
							              <span className="infoCircle"> 
                              <Tooltip 
                                placement="top"
                                title={item?.description}
                                key={index}
                                overlayClassName="examTooltip"
                              > 
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          </>
                        ) : (
                          <span className="currentQuestion">{item?.title}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8} className="answers">
                      {allInput(item, index)}
                    </Col>
                  </Row>
                );
              })}
              <div className="logInBtn">
                {current >= 1 && (
                  <Button
                    className="common_Button green_border_common_btn"
                    onClick={() => {
                      setCurrent(current - 6);
                    }}
                  >
                    <VerticalRightOutlined /> Previous{" "}
                  </Button>
                )}

                {steps?.length === lastArrayData?.index ? (
                  <Button
                    className="common_Button green_border_common_btn"
                    form={props.id}
                    loading={btnLoading}
                    htmlType="submit"
                  >
                    DONE
                  </Button>
                ) : (
                  <Button
                    className="common_Button green_border_common_btn"
                    form={props.id}
                    loading={btnLoading}
                    htmlType="submit"
                  >
                    Next <VerticalLeftOutlined />
                  </Button>
                )}
              </div>
            </FormBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillAssessmentFrom;
