import React from "react";
import { Button, Col, Form, Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./Rules";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/app";
import "../../Style/userProfile.less";
import { assignErrorToInput } from "../../../../../store/api";
import BreadcrumbComponent from "../../../CommonComponent/BreadcrumbComponent";
import { ProfileChangePasswordBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { changePassword } from "../../../../../store/AuthSlice";
import { useNavigate } from "react-router-dom";

/**
 * Renders the component for changing password of the user.
 *
 * @return {JSX.Element} The rendered component.
 */
const ProfileChangePassword = () => {
	const [form] = Form.useForm();
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate()

	/**
	 * Updates the user password in the profile with the given data. If an error occurs during the update,
	 * the error status is assigned to the input form.
	 *
	 * @param {any} data - The data to update the user's profile with.
	 * @return {Promise<void>} A promise that resolves when the update is complete.
	 */
	const userProfileUpdate = (data: any) => {
		dispatch(changePassword(data)).catch((error: any) =>
			assignErrorToInput(form, error?.STATUS)
		);
	};

	return (
		<div className="windBgImg">
			<div className="container">
				<div className="breadcrumb">
					<BreadcrumbComponent
						key={1}
						items={ProfileChangePasswordBreadcrumb.path}
					/>
				</div>

				<div className="profileLayout">
					<FormBox
						layout="vertical"
						autoComplete="off"
						form={form}
						id="userProfileUpdate"
						onFinish={userProfileUpdate}
						// onChange={handleChange}
						className="userProfile commanDrawer"
					>
						<Row gutter={30}>
							<Col sm={12} xs={24} md={8} xxl={8}>
								<InputBox.Password
									name="old_password"
									label="Old Password"
									placeholder="Old Password"
									rules={rules.password()}
								/>
							</Col>
							<Col sm={12} xs={24} md={8} xxl={8}>
								<InputBox.Password
									name="new_password"
									label="New Password"
									placeholder="New Password"
									rules={rules.password()}
								/>
							</Col>
							<Col sm={12} xs={24} md={8} xxl={8}>
								<InputBox.Password
									name="confirm_password"
									label="Confirm Password"
									placeholder="Confirm Password"
									rules={rules.password_confirmation()}
								/>
							</Col>
						</Row>

						<div className="profileBtn">
							<Button
								form="userProfileUpdate"
								className="common_Button green_border_common_btn"
								htmlType="submit"
							>
								Save
							</Button>
							<Button
								form="userProfileUpdate"
								className="common_Button green_border_common_btn"
								onClick={()=>navigate("/dashboard")}
							>
								Cancel
							</Button>
						</div>
					</FormBox>
				</div>
			</div>
		</div>
	);
};

export default ProfileChangePassword;
