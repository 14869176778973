import React, {  useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Row } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { AppDispatch, RootState } from "../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { rules } from "./Rules";
import { doRegister } from "../../../../store/AuthSlice";
import { Link, useNavigate } from "react-router-dom";
import { assignErrorToInput } from "../../../../store/api";
import { Notification, mobilePattern } from "../../../../config/Global";
import PageNotFound from "../../../errors/PageNotFound";
import { getStatus } from "../../HomePage/utils/slice";

/**
 * Renders the component for registration .
 *
 * @return {JSX.Element} The rendered component.
 */
const Register = () => {
	const dispatch: AppDispatch = useDispatch();
	const bannerContent = useSelector((state: RootState) => state.HOME?.pageSettings)
	const navigate = useNavigate();
	const [loading,setLoading] = useState(false)
	const [form] = Form.useForm();
	const [findCheck,setFindCheck] = useState<boolean>(true)
	const handleSubmit = (data: any) => {
		setLoading(true)
		dispatch(doRegister(data))
			.then(() => {
				navigate("/login");
				setLoading(false)
			})
			.catch((error: any) =>{
				 assignErrorToInput(form, error?.STATUS)
				 setLoading(false)
			});
	};

	const validateForm = () => {
		form.validateFields()
	};
	
	useEffect(()=>{
		dispatch(getStatus("homepage")).then((data:any)=>{
			if(data.options?.showRegister !== "1"){
				navigate("/");
				Notification.error({
					message: "You can't able to register",
					placement:"top",
				  });
			}
		}).catch((error: any) => error)
	},[])

	return bannerContent?.options?.showRegister === "1" ? (
		<>
			<section className="webLoginSection webRegisterSection">
				<div className="webLoginBox">
					<h2 className="subtitle">Sign Up</h2>
					{/* <p>Welcome to Offshore Wind Career</p> */}
					<FormBox form={form} onFinish={handleSubmit} onChange={validateForm}>
						<Row gutter={20}>
							<Col sm={24} xs={24} md={24} xxl={24}>
								<InputBox.Text
									name="name"
									placeholder="First Name"
									rules={rules.name()}
								/>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col sm={24} xs={24} md={24} xxl={24}>
								<InputBox.Text
									name="last_name"
									placeholder="Last Name"
									rules={rules.name()}
								/>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col sm={24} xs={24} md={24} xxl={24}>
								<InputBox.Text
									name="mobile_no"
									placeholder="000-000-0000"
									rules={rules.mobile()}
									maxLength={10}
                                    
                                    onChange={(e: any) => {

                                        if (e.target.value.length > 0) {

                                            form.setFieldsValue({ mobile_no:  mobilePattern(e.target.value) })                                            
        
                                        }
                                    }}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24} xs={24} md={24} xxl={24}>
								<InputBox.Text
									name="email"
									placeholder="Email"
									rules={rules.email()}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24} xs={24} md={24} xxl={24}>
								<InputBox.Password
									name="password"
									placeholder="Password"
									rules={rules.password()}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24} xs={24} md={24} xxl={24}>
								<InputBox.Password
									name="password_confirmation"
									placeholder="Confirm Password"
									rules={rules.password_confirmation()}
								/>
							</Col>
						</Row>
						<Divider />

						<Row>

							<Col sm={24} xs={24} md={24} xxl={24}>
								<Form.Item
									name="tearm_and_conditiom"

									rules={findCheck === false ? rules.tearmandconditiom() : null}
									required
								>
									<Checkbox
										defaultChecked={true}

										onClick={(e: any) => {
											e.target.checked
												? form.setFieldValue("tearm_and_conditiom", 1)
												: form.setFieldValue("tearm_and_conditiom", null)
										}}
										onChange={(e:any)=>{setFindCheck(e.target.checked)}}
									>
										By signing up you agree with our
									</Checkbox>
									<Link to="/terms-of-use" target="_blank" className="tearmAndConditiomLink">
										TERMS & CONDITIONS
									</Link>
								</Form.Item>
							</Col>
						</Row>

						<div className="logInBtn">
							<Button
								className="log_in_button secondry_btn_log_page"
								htmlType="submit"
								loading={loading}
							>
								Sign Up
							</Button>
						</div>
						<Divider>OR</Divider>
						<div className="logInBtn">
							<Button
								className="log_in_button secondry_btn_log_page"
								onClick={() => navigate("/login")}
							>
								Sign In
							</Button>
						</div>
					</FormBox>
				</div>
			</section>
		</>
	):<PageNotFound/>
};

export default Register;
