import React from "react";
import CountUp from "react-countup";
// import counterData from "./Counter.json"
// import user from "../../../../assets/webimage/user.svg"
import dotedImg from "../../../../assets/webimage/dot.png"
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/app";
import { WebConstant } from "../../webConstant/WebConstant";

/**
 * Renders a counter component with multiple counters, each displaying a count value and label 
 * for parameters like no. of careers, no. of training programs, etc.
 *
 * @return {JSX.Element} The counter component.
 */
const Counter = () => {
    const counterContent = useSelector((state: RootState) => state.HOME?.pageSettings)
    const counterData = counterContent?.counter
    let val = "+";

    const findCounter: any = (total: number) => {
        if (total / 1000000000000 > 1) {
            val = "T+";
            return total / 1000000000000;
        } else if (total / 1000000000 > 1) {
            val = "B+";
            return total / 1000000000;
        } else if (total / 1000000 >= 1) {
            val = "M+";
            return total / 1000000;
        } else if (total / 999 >= 1) {
            val = "K+";
            return total / 1000;
        } else {
            val = "+";
            return total.toFixed(0);
        }
    };
    return (
        <section className="counterWrap">
            <div>
                <img src={dotedImg} alt="dotedImg" className="dotedImg" />
            </div>
            <div className="container">
                <div className="couterSection">
                    <div className="counterContent">
                        <div className="counterInnerContent" >
                            {counterData?.counterImg_1 &&
                                <div className="counterUserImg">
                                    <img src={WebConstant?.IMAGE_URL + counterData.counterImg_1} alt="user" />
                                </div>
                            }
                            <div className="numberContent">
                                {counterData?.max_count_1 &&
                                    <CountUp start={0} end={findCounter(Number(counterData.max_count_1))} delay={0.5} suffix={` ${val}`} />
                                    // <CountUp start={0} end={1940} delay={0.5}  decimals={2}  suffix={` ${val}`} />
                                }
                                {counterData?.title_1 &&
                                    <h3 className="conterTitle">{counterData.title_1}</h3>
                                }
                            </div>
                        </div>
                        <div className="counterInnerContent" >
                            {counterData?.counterImg_2 &&
                                <div className="counterUserImg">
                                    <img src={WebConstant?.IMAGE_URL + counterData.counterImg_2} alt="user" />
                                </div>
                            }
                            <div className="numberContent">
                                {counterData?.max_count_2 &&
                                    <CountUp start={0} end={findCounter(Number(counterData.max_count_2))} delay={0.5} suffix={` ${val}`} />
                                }
                                {counterData?.title_2 &&
                                    <h3 className="conterTitle">{counterData.title_2}</h3>
                                }
                            </div>
                        </div>
                        <div className="counterInnerContent" >
                            {counterData?.counterImg_3 &&
                                <div className="counterUserImg">
                                    <img src={WebConstant?.IMAGE_URL + counterData.counterImg_3} alt="user" />
                                </div>
                            }
                            <div className="numberContent">
                                {counterData?.max_count_3 &&
                                    <CountUp start={0} end={findCounter(Number(counterData.max_count_3))} delay={0.5} suffix={` ${val}`} />
                                }
                                {counterData?.title_3 &&
                                    <h3 className="conterTitle">{counterData.title_3}</h3>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Counter