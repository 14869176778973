import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import PageSpinner from "../../../../../components/PageSpinner/PageSpinner";
import {
  fetchSkillAssessment,
  finishExam,
  startExam,
  submitAnswer,
} from "../Utils/slice";
import dayjs from "dayjs";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import SkillAssessmentFrom from "./SkillAssessmentCommon/SkillAssessmentFrom";
import { Notification } from "../../../../../config/Global";

/**
 * Renders the component for Skill portrait Add.
 *
 * @return {JSX.Element} The rendered component.
 */
const SkillAssessmentAdd = () => {
  const [current, setCurrent] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const allsteps = useSelector(
    (state: RootState) => state.WEBPROFILE.bothExamData
  );
  const RollId = useSelector((state: RootState) => state.WEBPROFILE.startExam);
  // const loading = useSelector((state: RootState) => state.WEBPROFILE.appLoading)
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // const [totalSeconds, setTotalSeconds] = useState<any>(0);
  // const [isActive, setIsActive] = useState(true);
  // const [formatedTime, setFormatedTime] = useState<any>()
  // const currentDate = dayjs().format('YYYY-MM-DD')
  const [fileList, setFileList] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  // const [allMaster, setAllMaster] = useState()
  // const [multipleList, setMultipleList] = useState<any>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const skillAssessmentData = await dispatch(
          fetchSkillAssessment()
        ).catch(() => {
          navigate("/dashboard");
        });

        const payload = {
          start_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          assessment_type: "1",
          question_ids: skillAssessmentData.data.ids,
        };
        // const tempArray: any = {};
        // const uniqueMasterTypes = Array.from(new Set(skillAssessmentData.data.questions.map((item: any) => item.answer_options === 2 && item.master_type)));

        // if (uniqueMasterTypes.length > 0) {
        //     for (const item of uniqueMasterTypes) {
        //         if (typeof item === "string") {
        //             try {
        //                 const result = await dispatch(masterLov(item));
        //                 tempArray[item] = result.data;
        //             } catch (error) {
        //                 error
        //                 // Handle the error appropriately if needed
        //             }
        //         }
        //     }
        // }

        // setAllMaster(tempArray);

        if (payload) {
          try {
            const data = await dispatch(startExam(payload));
            const base64EncodedId = btoa(data?.data?.exam_id);
            navigate(`/skill-assessment-exam-pending/${base64EncodedId}`);
            setLoading(false);
          } catch (examError) {
            examError;
            // Handle the error appropriately if needed
          }
        }
      } catch (error) {
        error;
        navigate("/dashboard");
        // Handle the error appropriately if needed
      }
    };

    fetchData();
  }, [dispatch]);

  // const onFocus = () => {
  //     setIsActive(true);
  // };
  // const onBlur = () => {
  //     setIsActive(false);
  // }
  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         if (isActive) {
  //             setTotalSeconds((prevTotalSeconds: any) => prevTotalSeconds + 1);
  //         }
  //     }, 1000);

  //     window.addEventListener("focus", onFocus);
  //     window.addEventListener("blur", onBlur);
  //     return () => {
  //         clearInterval(interval);
  //         window.removeEventListener("focus", onFocus);
  //         window.removeEventListener("blur", onBlur);
  //     };
  // }, [isActive]);

  // const steps = allsteps?.questions?.map((item: any) => ({
  //     key: item?.id,
  //     title: item?.question,
  //     content: item?.answers,
  //     question_id: item?.id,
  //     options: item?.answer_options
  // }));

  // *******************this code is set temporary remove after demo**********************

  // const questions = [
  //     {

  //         id: 1,
  //         question: "Project Management",
  //         type: 1
  //     },
  //     {

  //         id: 2,
  //         question: "Budgeting and Financial Management",
  //         type: 1
  //     },
  //     {

  //         id: 3,
  //         question: "Risk Management",
  //         type: 1
  //     },
  //     {

  //         id: 4,
  //         question: "Bachelor's degree in Engineering, Marine Science, or Merchant Marine ",
  //         type: 2
  //     },
  //     {

  //         id: 5,
  //         question: "Transportation Worker Identification Credential (TWIC)",
  //         type: 2
  //     },
  //     {

  //         id: 6,
  //         question: "Training/ Certificates/Licenses from an accredited school or industry-recognized organization such Global Wind Organization (GWO), OPITO, Onshore or Offshore Wind",
  //         type: 2
  //     },
  // ]
  // const steps = questions?.map((item: any, index: number) => ({
  //     key: item?.id,
  //     title: item?.question,
  //     content: item?.answers,
  //     question_id: item?.id,
  //     options: item?.answer_options,
  //     index: index + 1,
  //     type: item.type
  // }));
  const steps = allsteps?.questions?.map((item: any, index: number) => ({
    key: item?.id,
    title: item?.question,
    content: item?.answers,
    question_id: item?.id,
    options: item?.answer_options,
    master_type: item?.master_type,
    index: index + 1,
    description: item?.description,
  }));
  const itemsPerPage = 6;
  const startIndex = current;
  const endIndex = current + itemsPerPage;
  const getFirstSix = steps?.slice(startIndex, endIndex);
  const lastArrayData = getFirstSix && getFirstSix[getFirstSix?.length - 1]; // for identity done btn or next btn

  // const formatTime = () => {
  //     const hours = Math.floor(parseInt(totalSeconds) / 3600);
  //     const minutes = Math.floor((parseInt(totalSeconds) % 3600) / 60);
  //     const remainingSeconds = parseInt(totalSeconds) % 60;

  //     const hoursStr = String(hours).padStart(2, '0');
  //     const minutesStr = String(minutes).padStart(2, '0');
  //     const secondsStr = String(remainingSeconds).padStart(2, '0');
  //     setFormatedTime(`${hoursStr}:${minutesStr}:${secondsStr}`)
  //     return `${hoursStr}:${minutesStr}:${secondsStr}`;
  // };

  const nextButton = (data: any) => {
    setBtnLoading(true);

    // const formattedDateTime = `${currentDate} ${formatedTime}`;

    // if (steps[current].options === 3) {
    //     data.file = fileList[0].originFileObj
    //     data.answer = form.getFieldValue(`answer${steps[current].question_id}`),
    //         // data.current_time = formattedDateTime,
    //         data.question_type = "3"
    //     data.question_id = steps[current].question_id
    //     delete data[`answer${steps[current].question_id}`]

    //     const formData = new FormData()

    //     for (const file in data) {
    //         formData.append(file, data[file]);
    //         // formData.append(field, data[field] ? data[field] : null);
    //     }
    //     dispatch(submitAnswer(RollId.exam_id, formData))
    //         .then(() => {
    //             if (current + 1 === steps.length) {
    //                 completeExam(data)
    //             }
    //             else {
    //                 setCurrent(current + 1)
    //             }
    //             setMultipleList([...multipleList, { id: steps[current].question_id, list: fileList[0] }])
    //             setFileList([])
    //         })
    //         .catch((e: any) => e)
    // } else {

    //     data.answer = form.getFieldValue(`answer${steps[current].question_id}`),
    //         // data.current_time = formattedDateTime,
    //         data.question_type = steps[current].options
    //     data.question_id = steps[current].question_id
    //     delete data[`answer${steps[current].question_id}`]

    //     if (current + 1 === steps.length) {
    //         dispatch(submitAnswer(RollId.exam_id, data))
    //             .then(() => {
    //                 completeExam(data)
    //             })
    //             .catch((e: any) => e)
    //     } else {
    //         dispatch(submitAnswer(RollId.exam_id, data)).then(() => {
    //             setCurrent(current + 1)
    //         }).catch((e: any) => e)
    //     }

    // }
    const newArray = [];
    for (const [ques_id, answer] of Object.entries(data)) {
      const findQuestionType = getFirstSix.find(
        (item: any) => item.question_id === Number(ques_id)
      );
      newArray.push({
        ques_id,
        question_type: findQuestionType.options,
        answer,
      });
    }
    data.answers = newArray;
    data.assessment_type = "1";
    const keysToRemove = getFirstSix.map((item: any) => item.question_id);
    keysToRemove.forEach((key: any) => {
      delete data[key];
      return data;
    });
    if (steps?.length === lastArrayData?.index) {
      dispatch(submitAnswer(RollId.exam_id, data))
        .then(() => {
          completeExam(data);
        })
        .catch((e: any) => {
          e;
          setBtnLoading(false);
        });
    } else {
      dispatch(submitAnswer(RollId.exam_id, data))
        .then(() => {
          setBtnLoading(false);
          setCurrent(current + itemsPerPage);
        })
        .catch((e: any) => {
          e;
          setBtnLoading(false);
        });
    }
  };

  useEffect(() => {
    //scroll to top on next button
    if (!btnLoading) {
      if (document.body.clientHeight - 4 > window.innerHeight) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [nextButton, btnLoading]);

  // Function to be called when user has clicked on previous button to show previous 6 questions
  const prev = () => {
    setCurrent(current - 1);
  };

  // Function to be called when user has answered all questions
  const completeExam = (data: any) => {
    (data.current_time = dayjs().format("YYYY-MM-DD HH:mm:ss")),
      dispatch(finishExam(RollId.exam_id, data))
        .then(() => {
          navigate("/report");
          setLoading(true);
          setBtnLoading(false);
        })
        .catch((e: any) => {
          Notification.error({
            message: e.STATUS.id[0],
          });
          setBtnLoading(false);
        });
  };

  // validate form fields after clicking on next button
  const validateForm = () => {
    form.validateFields();
  };

  return !loading && allsteps ? (
    <SkillAssessmentFrom
      steps={steps}
      nextButton={nextButton}
      form={form}
      current={current}
      setCurrent={setCurrent}
      // formatTime={formatTime}
      setFileList={setFileList}
      fileList={fileList}
      prev={prev}
      getFirstSix={getFirstSix}
      lastArrayData={lastArrayData}
      validateForm={validateForm}
      btnLoading={btnLoading}
      // allMaster={allMaster}
    />
  ) : (
    <PageSpinner />
  );
};

export default SkillAssessmentAdd;
