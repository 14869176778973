import { BreadcrumbConfigProps } from "./InterfacesAndTypes";

const defaultBreadcrumbPath = [
	{ name: "Dashboard", link: '/dashboard' },
];
export const DashboardBreadcrumb: BreadcrumbConfigProps = {
	title: "Dashboard",
	path: [...defaultBreadcrumbPath],
};

export const UserBreadcrumb: BreadcrumbConfigProps = {
	title: 'User Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'User Management', link: '/users' }
	]
}

export const RoleBreadcrumb: BreadcrumbConfigProps = {
	title: 'Roles Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Roles', link: '/roles' }
	]
}

export const UserProfileBreadcrumb: BreadcrumbConfigProps = {
	title: 'User Profile',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'User Profile' }
	]
}

export const ProfileChangePasswordBreadcrumb: BreadcrumbConfigProps = {
	title: 'Change Password',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Change Password' }
	]
}
export const InterestAssessmentBreadcrumb: BreadcrumbConfigProps = {
	title: 'Interest Discovery',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Interest Discovery' }
	]
}
export const SkillAssessmentBreadcrumb: BreadcrumbConfigProps = {
	title: 'Skill Portrait',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Skill Portrait' }
	]
}
export const LocationsBreadcrumb: BreadcrumbConfigProps = {
	title: 'Locations',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Locations' }
	]
}
export const ResultBreadcrumb: BreadcrumbConfigProps = {
	title: 'Career Compass',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Career Compass' }
	]
}

