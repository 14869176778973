import React from "react";
// import { Spin } from "antd";
import loaderImg from "../../assets/webimage/loaderFan.gif"

const PageSpinner: React.FC = () => (
	<div className={"fullscreen__spinner__wrapper"}>
		{/* <Spin size={"large"} /> */}
		<img src={loaderImg} alt="loading" />
	</div>
);

export default PageSpinner;
