import React, { useEffect } from 'react'
import Footer from '../CommonComponent/Footer'
import CmsComponent from './Component/CmsComponent'
import { useLocation } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { cmsPage } from './Utils/Slice'
import { WebConstant } from '../webConstant/WebConstant'
import PageSpinner from '../../../components/PageSpinner/PageSpinner'

/**
 * Renders the Cmspages component for terms and conditions, privacy policy, and about us
 * according to the path url.
 *
 * @return {JSX.Element} The rendered Cmspages component.
 */
const Cmspages = () => {
    const location = useLocation()
    const dispatch: AppDispatch = useDispatch();
    const cmsPageData = useSelector((state: RootState) => state.CMSPAGES.cmsPage)
    const pageLoading = useSelector((state: RootState) => state.CMSPAGES.appLoading)

    useEffect(() => {
        if (location.pathname.includes(WebConstant.ABOUT)) {
            dispatch(cmsPage(WebConstant.ABOUT))
        } else if (location.pathname.includes(WebConstant.PRIVACY)) {
            dispatch(cmsPage(WebConstant.PRIVACY))
        } else if (location.pathname.includes(WebConstant.TERMS)) {
            dispatch(cmsPage(WebConstant.TERMS))
        }
    }, [location])

    return (
        <>
            {pageLoading ? <PageSpinner /> :
                <>
                    {cmsPageData &&
                        <CmsComponent data={cmsPageData} />
                    }
                    <Footer />
                </>
            }
        </>
    )
}

export default Cmspages