import { validations } from "../../../../config/validations/validations";


export const rules: any = {
	email: (field?: string) => [
		validations.required.text(field),
		validations.email(field),
	],
	password: (field?: any) => [
		validations.required.text(field),
		validations.pattern.password(field),
	],
};