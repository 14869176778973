import React, { useEffect } from "react";
import { Layout } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/app";
import LogInRegisterHeader from "../../webPages/CommonComponent/LogInRegisterHeader";
import Footer from "../../webPages/CommonComponent/Footer";

/**
 * Renders the wrapper layout component for login, register ,
 * reset password and forgot password.
 *
 * @return {ReactElement} The rendered authentication layout component.
 */
const AuthLayout: React.FC = () => {
  const navigate = useNavigate();
  const Auth = useSelector((state: RootState) => state.AUTH);
  const webTrackingUrl = useLocation()
  
// If user is already logged in, redirect to dashboard
  useEffect(() => {
    if (Auth.token) {
      navigate("/dashboard");
    }
  }, [Auth.token, navigate]);

  return (
    <Layout className={`main__page__wrapper has__header`}>
      <Layout.Content className="main__page__content">
        {webTrackingUrl.pathname !== "/" &&
          <LogInRegisterHeader />
        }
        <Outlet />
        {webTrackingUrl.pathname !== "/" &&
          <Footer />
        }
      </Layout.Content>
    </Layout>
  );
}

export default AuthLayout;
