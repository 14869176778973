import React, { useEffect, useState } from 'react'
import { AppDispatch, RootState } from '../../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { ViewIntersetExam, masterLov } from '../Utils/slice'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'
import SkillAssessmentFrom from './SkillAssessmentCommon/SkillAssessmentFrom'
// import dayjs from 'dayjs'

/**
 * Renders component to view questions and given answer of Skill portrait exam.
 *
 * @return {JSX.Element} The rendered Skill Assessment View component.
 */
const SkillAssessmentView = () => {
    const dispatch: AppDispatch = useDispatch()
    const { id }: any = useParams()
    const decodedDataId = atob(id);
    const allsteps = useSelector((state: RootState) => state.WEBPROFILE.bothExamData)
    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const navigate = useNavigate()
    // const loading = useSelector((state: RootState) => state.WEBPROFILE.appLoading)
    // const [totalSeconds, setTotalSeconds] = useState<any>(0);
    const [loading, setLoading] = useState(true)
    const [allMaster, setAllMaster] = useState()


    useEffect(() => {

        // Api to be called when the component mounts
        const fetchData = async () => {
            try {
                const data = await dispatch(ViewIntersetExam(decodedDataId));

                const tempArray: any = {};
                const uniqueMasterTypes = Array.from(new Set(data.data.questions.map((item: any) => item.type === 2 && item.master_type)));

                if (uniqueMasterTypes.length > 0) {
                    for (const item of uniqueMasterTypes) {
                        if (typeof item === "string") {
                            try {
                                const result = await dispatch(masterLov(item));
                                tempArray[item] = result.data;
                            } catch (error) {
                                error
                            }
                        }
                    }
                }
                setAllMaster(tempArray)

                if (data?.data?.attends?.length > 0) {                    
                    data.data.attends.map((item: any) => form.setFieldValue(item.question_id, parseInt(item?.answer)));
                    setCurrent(0);
                }

                setLoading(false);
            } catch (error) {
                error
            }
        };

        fetchData();
    }, [])
    

    const steps = allsteps?.questions?.map((item: any, index: number) => ({
        key: item?.id,
        title: item?.question,
        content: item?.answers,
        question_id: item?.id,
        options: item?.type,
        master_type: item?.master_type,
        index: index + 1,
        description:item?.description
    }));   
 
    
    const itemsPerPage = 6;
    const startIndex = current;
    const endIndex = current + itemsPerPage;
    const getFirstSix = steps?.slice(startIndex, endIndex);
    const lastArrayData = getFirstSix && getFirstSix[getFirstSix?.length - 1];
    

    // useEffect(() => {
    //     if (steps?.length > 0 && allsteps) {
    //         const ans = allsteps?.attends?.find((item: any) => {
    //             return (
    //                 item.question_id === steps[current].question_id
    //             )
    //         })
    //         if (steps[current].options === 1) {
    //             form.setFieldValue(`answer${steps[current].question_id}`, parseInt(ans?.answer))
    //         } else if (steps[current].options === 2) {
    //             form.setFieldValue(`answer${steps[current].question_id}`, ans?.answer)
    //         } else {
    //             form.setFieldValue(`answer${steps[current].question_id}`, WebConstant?.IMAGE_URL + ans?.answer)
    //             setShowFile(ans?.answer)
    //             setFileList([
    //                 {
    //                     uid: "-1",
    //                     status: "done",
    //                     url: WebConstant?.IMAGE_URL + ans?.answer,
    //                     thumbUrl: WebConstant?.IMAGE_URL + ans?.answer,
    //                     name: ans?.answer ? ans?.answer.substring(ans?.answer.lastIndexOf('/') + 1) : null,
    //                 },
    //             ])
    //         }
    //     }



    // }, [current, allsteps])

    // Function to be called when user has clicked on next button.
    const nextButton = () => {
        setCurrent(current + 6)
        if (document.body.clientHeight > window.innerHeight) {
            window.scrollTo(0, 0);
        }
        if (lastArrayData?.index === steps.length) {
            navigate("/dashboard")
        }
    }

    //scroll to top on next button click
    useEffect(()=>{ 
        if ((document.body.clientHeight-4) > window.innerHeight) {
                       window.scrollTo({ top: 0, behavior: "smooth" });
                   }
       },[nextButton])
    // const formatTime = () => {
    //     const hours = Math.floor(parseInt(totalSeconds) / 3600);
    //     const minutes = Math.floor((parseInt(totalSeconds) % 3600) / 60);
    //     const remainingSeconds = parseInt(totalSeconds) % 60;

    //     const hoursStr = String(hours).padStart(2, '0');
    //     const minutesStr = String(minutes).padStart(2, '0');
    //     const secondsStr = String(remainingSeconds).padStart(2, '0');
    //     return `${hoursStr}:${minutesStr}:${secondsStr}`;
    // };

    // Function to be called when user has clicked on previous button to show previous 6 questions
    const prev = () => {
        setCurrent(current - 1)
    }
    return !loading && allsteps ? (
        <SkillAssessmentFrom
            steps={steps}
            nextButton={nextButton}
            form={form}
            current={current}
            setCurrent={setCurrent}
            // formatTime={formatTime}
            prev={prev}
            getFirstSix={getFirstSix}
            lastArrayData={lastArrayData}
            isView={true} 
            allMaster={allMaster}
        />
    ) : <PageSpinner />
}

export default SkillAssessmentView