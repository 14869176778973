import React, { useEffect, useState } from 'react'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { Button, Checkbox, Col, Divider, Form, Row } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store/app'
import { rules } from './Rules'
import { useNavigate } from 'react-router-dom'
import { doLogin, setSessionTimeOut } from '../../../../store/AuthSlice'
import { assignErrorToInput } from '../../../../store/api'
import { stringDecryption } from '../../../../config/Global'

/**
 * Renders the Login component contains login form.
 *
 * @return {JSX.Element} The rendered WebLogin component.
 */
const WebLogin = () => {
    const [form] = Form.useForm();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate()
    const [rememberMe, setRememberMe] = useState(false);
    const bannerContent = useSelector((state: RootState) => state.HOME?.pageSettings)

    // Submit Login Form and navigate to dashboard
    const handleSubmit = (data: any) => {
        data.remember = rememberMe
        dispatch(doLogin(data))
            .then(() => {
                navigate("/dashboard");
                dispatch(setSessionTimeOut(true))
            })
            .catch((error: any) => assignErrorToInput(form, error?.STATUS))
    }

    // set User credentials in login form from local storage
    useEffect(() => {
        let rememberMe: any = localStorage.getItem("remember_me");
        if (rememberMe) {
            rememberMe = JSON.parse(stringDecryption(rememberMe));
            form.setFieldsValue({
                username: rememberMe.email,
                password: rememberMe.password,
                remember: true,
            });
            setRememberMe(true)
        }
    }, [form]);
    return (
        <>
            <section className='webLoginSection'>
                <div className='webLoginBox'>
                    <h2 className="subtitle">Welcome Back</h2>
                    <FormBox form={form} onFinish={handleSubmit}>
                        <InputBox.Text
                            name="username"
                            placeholder='Email Address'
                            rules={rules.email()}
                        />
                        <InputBox.Password
                            name="password"
                            placeholder='Password'
                            rules={rules.password()}
                        />
                        <div className='forgetPass'>
                            <Row align={"middle"} >
                                <Col sm={12} xs={12} md={12} xxl={12}>
                                    <Checkbox name='remember_me' className='rememberMe' checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}>Remember me</Checkbox>
                                </Col>
                                <Col sm={12} xs={12} md={12} xxl={12} className='forgotPassLink'>
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </Col>
                            </Row>
                            <div className='logInBtn'>
                                <Button className='log_in_button secondry_btn_log_page' htmlType='submit'>
                                    Log In
                                </Button>
                            </div>
                            {(bannerContent?.options?.showRegister === "1") &&
                                <>
                                    <Divider>OR</Divider>
                                    <h5 className='register_here'>Don’t Have An Account?<span onClick={() => navigate("/register")}>Register Here</span></h5>
                                </>
                            }
                        </div>
                    </FormBox>
                </div>
            </section>
        </>
    )
}

export default WebLogin