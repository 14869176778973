import React, { useEffect, useState } from 'react'
import { fetchInterestAssessment, finishExam, startExam, submitAnswer, tierOneSubmitAnswer } from '../Utils/slice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../store/app'
import dayjs from 'dayjs'
import InterestAssessmentForm from './InterestAssessmentCommon/InterestAssessmentForm'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'

/**
 * Renders the component for new Interest Discovery Exam.
 *
 * @return {JSX.Element} The rendered component.
 */
const InterestAssessmentAdd = () => {
    const [current, setCurrent] = useState(0);
    const dispatch: AppDispatch = useDispatch()
    const allsteps = useSelector((state: RootState) => state.WEBPROFILE.bothExamData)
    const RollId = useSelector((state: RootState) => state.WEBPROFILE.startExam)
    // const loading = useSelector((state: RootState) => state.WEBPROFILE.appLoading)
    const [form] = Form.useForm();
    const navigate = useNavigate();
    // const [totalSeconds, setTotalSeconds] = useState<any>(0);
    // const [formatedTime, setFormatedTime] = useState<any>()
    // const currentDate = dayjs().format('YYYY-MM-DD')
    // const [isActive, setIsActive] = useState(true);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [updateSteps, setUpdateSteps] = useState<any>([]);

    // For fetching first 36 question for the exam
    useEffect(() => {
        dispatch(fetchInterestAssessment()).then((data: any) => {
            const payload = {
                start_time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                assessment_type: "2",
                question_ids: data.data.ids
            }
            const steps = data?.data?.questions?.map((item: any, index: number) => ({
                key: item?.id,
                title: item?.question,
                content: item?.answers,
                question_id: item?.id,
                options: item?.answer_options,
                index: index + 1,
                disabled: true
            }));
            setUpdateSteps(steps)

            if (payload) {
                dispatch(startExam(payload))
                    .then((data:any) => {
                        const base64EncodedId = btoa(data?.data?.exam_id);
                        navigate(`/interest-assessment-exam-pending/${base64EncodedId}`)
                        setLoading(false)
                    }).catch((e: any) => e)
            }
        }).catch((e: any) => e)
    }, [])

    // const onFocus = () => {
    //     setIsActive(true);
    // };
    // const onBlur = () => {
    //     setIsActive(false);
    // }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (isActive) {
    //             setTotalSeconds((prevTotalSeconds: any) => prevTotalSeconds + 1);
    //         }
    //     }, 1000);


    //     window.addEventListener("focus", onFocus);
    //     window.addEventListener("blur", onBlur);
    //     return () => {
    //         clearInterval(interval);
    //         window.removeEventListener("focus", onFocus);
    //         window.removeEventListener("blur", onBlur);
    //     };
    // }, [isActive]);


    // const formatTime = () => {
    //     const hours = Math.floor(parseInt(totalSeconds) / 3600);
    //     const minutes = Math.floor((parseInt(totalSeconds) % 3600) / 60);
    //     const remainingSeconds = parseInt(totalSeconds) % 60;

    //     const hoursStr = String(hours).padStart(2, '0');
    //     const minutesStr = String(minutes).padStart(2, '0');
    //     const secondsStr = String(remainingSeconds).padStart(2, '0');
    //     setFormatedTime(`${hoursStr}:${minutesStr}:${secondsStr}`)
    //     return `${hoursStr}:${minutesStr}:${secondsStr}`;
    // };

    const itemsPerPage = 6;
    const startIndex = current;
    const endIndex = current + itemsPerPage;
    const getFirstSix = updateSteps?.length > 0 && updateSteps?.slice(startIndex, endIndex);
    const lastArrayData = getFirstSix && getFirstSix[getFirstSix?.length - 1]; // for identity done btn or next btn
    
    // Button to move to next page and fetch remaining questions after 36 questions
    const nextButton = (data: any) => {
        const newArray = [];
        // Iterate over the key-value pairs and push them into the new array
        for (const [ques_id, ans_id] of Object.entries(data)) {
            newArray.push({ ques_id, ans_id });
        }
        data.answers = newArray
        data.assessment_type = "2"
        const keysToRemove = getFirstSix.map((item: any) => item.question_id);
        keysToRemove.forEach((key: any) => {
            delete data[key];
            return data
        });
        setBtnLoading(true)
        // if (lastArrayData?.index === steps.length) {
        //     dispatch(submitAnswer(RollId.exam_id, data))
        //         .then(() => {
        //             completeExam(data)
        //             setBtnLoading(false)
        //         })
        //         .catch((e: any) => {
        //             e
        //             setBtnLoading(false)
        //         })
        // } else {
        //     dispatch(submitAnswer(RollId.exam_id, data)).then(() => {
        //         // setCurrent(current + itemsPerPage)
        //         setCurrent(current + itemsPerPage);
        //         setBtnLoading(false)
        //     }).catch((e: any) => {
        //         e
        //         setBtnLoading(false)
        //     })
        // }

        if (lastArrayData?.index === 50) {
            
            completeExam(data)
        } else if (updateSteps.length > 36 && lastArrayData.disabled) {
            setBtnLoading(false)
            setCurrent(current + itemsPerPage);
        } else {
            dispatch(submitAnswer(RollId.exam_id, data))
                .then(() => {
                    if (lastArrayData?.index === 36 && updateSteps.length != 50) {
                        dispatch(tierOneSubmitAnswer(RollId.exam_id)).then((res: any) => {
                            const steps = res?.data?.questions.map((item: any, index: number) => ({
                                key: item?.id,
                                title: item?.question,
                                content: item?.answers,
                                question_id: item?.id,
                                options: item?.answer_options,
                                index: lastArrayData.index + index + 1,
                                disabled: false
                            }));
                            setUpdateSteps([...updateSteps, ...steps])
                            setCurrent(current + itemsPerPage);
                            setBtnLoading(false)
                        })
                            .catch((e: any) => {
                                e
                                navigate("/dashboard")
                            })
                    } else {
                        setCurrent(current + itemsPerPage);
                        setBtnLoading(false)
                    }
                })
                .catch((e: any) => {
                    e
                    setBtnLoading(false)
                })
        }

    }

    // For scroll to the top after clicking on next button
    useEffect(()=>{
        if(!btnLoading){
        if ((document.body.clientHeight-4) > window.innerHeight) {
                       window.scrollTo({ top: 0, behavior: "smooth" });
                   }}
       },[nextButton,btnLoading])
    
    // For submitting the exam after all the questions are answered
    const completeExam = (data: any) => {
      
        data.current_time = dayjs().format('YYYY-MM-DD HH:mm:ss'),
            dispatch(submitAnswer(RollId.exam_id, data)).then(() => {
                dispatch(finishExam(RollId.exam_id,data)).then(() => {
                    navigate("/dashboard")
                })
                    .catch((e: any) => {
                        e
                        navigate("/dashboard")
                        setBtnLoading(false)
                    })
            }).catch((e: any) => {
                e
                setBtnLoading(false)
            })


    }
    const validateForm = () => {
        form.validateFields()
    };

    return !loading && allsteps ? (
        <InterestAssessmentForm
            steps={updateSteps}
            nextButton={nextButton}
            form={form}
            current={current}
            setCurrent={setCurrent}
            type="add"
            id="InterestAssessmentFormAdd"
            validateForm={validateForm}
            getFirstSix={getFirstSix}
            lastArrayData={lastArrayData}
            // formatTime={formatTime}
            btnLoading={btnLoading}
        />
    )
        : <PageSpinner />
}

export default InterestAssessmentAdd