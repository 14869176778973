
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as SliderArrow } from "../../../assets/webimage/LeftArrow.svg";
import { contentMarkup } from '../../../config/Global';

/**
 * Renders a simple slider component in the testimonial section of the home page.
 *
 * @param {any} props - The props object containing slideData.
 * @return {JSX.Element} The rendered slider component.
 */
const SimpleSlider = (props: any) => {
	const slider = React.useRef<any>(null);

	//Contains the properties for the slider
	const settings = {
		dots: false,
		infinite: true,
		speed: 1300,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
		// centerMode: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768, // Mobile devices in portrait mode
				settings: {
					slidesToShow: 1, // Number of slides to show on screens >= 768px
				},
			},
			

		]
	};


	return props.slideData?.length > 2
		? <>
			<Slider ref={slider} {...settings} className='testimonialSlider' >
			{props.slideData.map((item: any, index: number) => (
				<div key={index} className="slide-item" >
					{/* <p>{item.description}</p> */}
					<div dangerouslySetInnerHTML={contentMarkup(item.description)}></div>
					<div className='details'>
						<span>{item.title}</span>
						{item.subtitle}
					</div>
				</div>
			))}
			</Slider>
			<div className='sliderNav'>
				<SliderArrow onClick={() => slider.current.slickPrev()}
					className='sliderArrow' />
				<SliderArrow onClick={() => slider.current.slickNext()}
					className='sliderArrow rightArrow' />
			</div>
		</>
		: <div className='testimonialSlider noSlider'>
			{props.slideData.map((item: any, index: number) => (
				<div key={index} className="slide-item" >
					{/* <p>{item.description}</p> */}
					<div dangerouslySetInnerHTML={contentMarkup(item.description)}></div>
					<div className='details'>
						<span>{item.title}</span>
						{item.subtitle}
					</div>
				</div>
			))}
		</div>

};
export default SimpleSlider
