import { validations } from "../../../../../../config/validations/validations";



export const rules: any = {
    textAnswer: () => [
        validations.required.text()
    ],
    answer: () => [
        validations.required.withoutItSelect()
    ],
};