import React, { useEffect, useState } from 'react'
import { AppDispatch, RootState } from '../../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { ViewIntersetExam } from '../Utils/slice'
import { useNavigate, useParams } from 'react-router-dom'
import InterestAssessmentForm from './InterestAssessmentCommon/InterestAssessmentForm'
import { Form } from 'antd'
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'
// import dayjs from 'dayjs'

/**
 * Renders the component to  view Interest Discovery Exam.
 *
 * @return {JSX.Element} The rendered component.
 */
const InterestAssessmentView = () => {
    const dispatch: AppDispatch = useDispatch()
    const { id }:any = useParams()
    const decodedDataId = atob(id);
    const allsteps = useSelector((state: RootState) => state.WEBPROFILE.bothExamData)
    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const navigate = useNavigate()
    // const loading = useSelector((state: RootState) => state.WEBPROFILE.appLoading)
    // const [totalSeconds, setTotalSeconds] = useState<any>(0);
    const [loading, setLoading] = useState(true)
    const steps = allsteps?.questions?.map((item: any, index: number) => ({
        key: item?.id,
        title: item?.question,
        content: item?.answers,
        question_id: item?.id,
        options: item?.answer_options,
        index: index + 1
    }));
    const itemsPerPage = 6;
    const startIndex = current;
    const endIndex = current + itemsPerPage;
    const getFirstSix = steps?.slice(startIndex, endIndex);
    const lastArrayData = getFirstSix && getFirstSix[getFirstSix?.length - 1]; // for identity done btn or next btn

    // For fetching question and answer for the exam 
    useEffect(() => {
        dispatch(ViewIntersetExam(decodedDataId))
            .then((res: any) => {
                if (res?.data?.attends) {
                    res.data.attends.map((item: any) => form.setFieldValue(item.question_id, item?.answer))
                }
                setLoading(false)
            }).catch((e: any) => e).catch((e: any) => e)
           
    }, [])
    
    // useEffect(() => {
    //     if (steps?.length > 0 && getFirstSix) {
    //         const ans = getFirstSix?.attends?.find((item: any) => {
    //             return (
    //                 item.question_id === steps[current].question_id
    //             )
    //         })
    //         form.setFieldValue(`answer${steps[current].question_id}`, parseInt(ans?.answer))
    //     }

    // }, [current, allsteps])

    // Button to view next 6 questions
    const nextButton = () => {
        setCurrent(current + 6)
       
        if (lastArrayData?.index === steps.length) {
            navigate("/dashboard")
        }
    }

    // For scroll to the top after clicking on next button
    useEffect(()=>{
        if ((document.body.clientHeight-4) > window.innerHeight) {
                       window.scrollTo({ top: 0, behavior: "smooth" });
                   }
       },[nextButton])
    // const formatTime = () => {
    //     const hours = Math.floor(parseInt(totalSeconds) / 3600);
    //     const minutes = Math.floor((parseInt(totalSeconds) % 3600) / 60);
    //     const remainingSeconds = parseInt(totalSeconds) % 60;

    //     const hoursStr = String(hours).padStart(2, '0');
    //     const minutesStr = String(minutes).padStart(2, '0');
    //     const secondsStr = String(remainingSeconds).padStart(2, '0');
    //     return `${hoursStr}:${minutesStr}:${secondsStr}`;
    // };
    
    return !loading && allsteps ? (
        <InterestAssessmentForm
            steps={steps}
            nextButton={nextButton}
            form={form}
            current={current}
            setCurrent={setCurrent}
            type='view'
            id="InterestAssessmentFormView"
            getFirstSix={getFirstSix}
            lastArrayData={lastArrayData}
        // formatTime={formatTime}
        />
    ) : <PageSpinner />
}

export default InterestAssessmentView