import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../../store/app';
import { verifyEmail } from '../../../../store/AuthSlice';

/**
 * Path of this page given in mail confirmation with token in URL
 * that navigate user to login page after verification.
 *
 * @return {JSX.Element} Returns a div element.
 */
const VerifyAccount = () => {
    const navigate = useNavigate();
    const { token }: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(verifyEmail(token)).then(()=>{           
            navigate("/login")
        })
        .catch(()=>{
            navigate("/")
        })        
    },[])

  return (
    <div></div>
  )
}

export default VerifyAccount