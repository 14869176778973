import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./config/RouterConfig";
import { initialAppOptions } from "./store/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/app";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import { getStatus } from "./views/webPages/HomePage/utils/slice";

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const AUTH = useSelector((state: RootState) => state.AUTH);

  useEffect(() => {
    dispatch(initialAppOptions()).catch((error: any) => error);

    dispatch(getStatus("homepage")).catch((error: any) => error);
  }, [dispatch]);
  
  return (
    <BrowserRouter>
      {AUTH.appLoading ? <PageSpinner /> : <Router />}
    </BrowserRouter>
  );
};

export default App;
