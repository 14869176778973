import React, { useEffect, useRef, useState } from "react";
import { Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import HeaderView from "./HeaderView";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import Footer from "../../webPages/CommonComponent/Footer";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { Notification } from "../../../config/Global";
import { refreshToken } from "../../../store/AuthSlice";

/**
 * Renders the main layout of the application with header and footer components.
 *
 @return {ReactElement} The main layout component.
 */
const MainLayout: React.FC = () => {
  const [collapsed, SetCollapsed] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [timeOutFlag, setTimeOutFlag] = useState<boolean>(false);
  const [timeInFlag, setTimeInFlag] = useState<boolean>(false);
  const Auth = useSelector((state: RootState) => state.AUTH);
  const sessionTimeoutFlag = useSelector(
    (state: RootState) => state.AUTH.sessionTimeOut
  );

  const [loading, setLoading] = useState(true);
  const idleTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!Auth.token) {
      navigate("/login");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [Auth.token, navigate, dispatch]);

  // Code for user to be logged out after 1 hour of inactivity : start
  const idleDuration = 1 * 60 * 60; // 1 hour in seconds

  window.addEventListener("beforeunload", function () {
    localStorage.setItem("timeOut", new Date().getTime().toString());
  });

  useEffect(() => {
    const timeOut = JSON.parse(localStorage.getItem("timeOut") || "0");
    var redirectUrl = "/login"; // Redirect idle users to this URL

    if (!sessionTimeoutFlag) {
      if (new Date().getTime() - timeOut >= idleDuration * 1000) {
        localStorage.removeItem("token"); 
        Notification.error({
          message: "Session expired.",
        });
        const clearTime = setTimeout(() => {
          window.location.pathname = redirectUrl;
        }, 500);
        return () => clearTimeout(clearTime);
      }
    }
  }, []);

  useEffect(() => {
    var redirectUrl = "/login"; // Redirect idle users to this URL

    const resetIdleTimeout = () => {
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
      idleTimeoutRef.current = setTimeout(() => {
        if (!timeOutFlag) {
          localStorage.removeItem("token");
          setTimeOutFlag(true);
          Notification.error({
            message: "Session expired.",
          });
          const clearTime = setTimeout(() => {
            window.location.pathname = redirectUrl;
          }, 500);
          return () => clearTimeout(clearTime);
        }
      }, idleDuration * 1000);
    };

    resetIdleTimeout();

    const events = ["click", "touchstart", "mousemove"];
    events.forEach((evt) =>
      document.addEventListener(evt, resetIdleTimeout, false)
    );

    // Cleanup event listeners on component unmount
    return () => {
      events.forEach((evt) =>
        document.removeEventListener(evt, resetIdleTimeout, false)
      );
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
    };
  }, [timeOutFlag]);
  // Code for user to be logged out after 1 hour of inactivity : end	

  // Code for refresh token :Start
  useEffect(() => { 

    const tokenRefreshTime = 22 * 60 * 60; //Duration of time in seconds
    const checkInterval  = 1 * 60 * 60;
    const nextRefresh = 21 * 60 * 60;  

    const isWithin0To10PercentHigher = (value1:any, value2:any) => {
      if (value2 === 0) {
          throw new Error("Division by zero is not allowed");
      }

      if(value1 < value2 ) {
        return false;
      }

      const result = Math.abs(value1 / value2);
      const subtractionResult = result-Math.floor(result);
      return subtractionResult >= 0 && subtractionResult <= 0.1;
    }

    const intervalId = setInterval(() => {
      const timeIn = JSON.parse(localStorage.getItem("timeIn") || "0");

      const elapsedSeconds  = Math.round((new Date().getTime() - Number(timeIn)) / 1000);

      if (isWithin0To10PercentHigher(elapsedSeconds,tokenRefreshTime) && !timeInFlag) {
        setTimeInFlag(true);  
        dispatch(refreshToken()); 
                
        // Set a timeout to reset the flag after the specified seconds
        setTimeout(() => {
          setTimeInFlag(false);
        }, nextRefresh * 1000);
      }
    }, checkInterval * 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [timeInFlag]);

  // Code for refresh token :End

  return !loading ? (
    <Layout className={`main__page__wrapper has__appsidebar`}>
      <Layout className="site-layout">
        <HeaderView collapsed={collapsed} SetCollapsed={SetCollapsed} />
        <Layout.Content className="main__app__content">
          <Outlet />
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  ) : (
    <PageSpinner />
  );
};

export default MainLayout;
