import React, { useState } from "react";
import Banner from "./components/Banner";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/app";
import "../style/homePage.less"
import Counter from "./components/Counter";
import ToolProcess from "./components/ToolProcess";
import EnergyRaining from "./components/EnergyRaining";
import Achive from "./components/Achive";
import Community from "./components/Community";
import TrainingSolutions from "./components/TrainingSolutions";
import Footer from "../CommonComponent/Footer";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { Button, Checkbox, Modal, } from "antd";
import { WebConstant } from "../webConstant/WebConstant";
import HelmetComponent from "../CommonComponent/HelmetComponent";
import Testimonial from "./components/Testimonial";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { contentMarkupHeader } from "../../../config/Global";
import { Link } from "react-router-dom";

/**
 * Renders the HomePage component.
 *
 * @return {JSX.Element} The rendered HomePage component.
 */
const HomePage = () => {
	const HOME = useSelector((state: RootState) => state.HOME)
	const [openModal, setOpenModal] = useState<boolean>(true)
	const [videoModal, setVideoModal] = useState<boolean>(false)
	const modalVideo = HOME?.pageSettings?.options?.youtube_url;
	const videoId = modalVideo?.split('v=')[1];
	const storageVideoModal: any = localStorage.getItem("videoModal")
	const communityContent = useSelector((state: RootState) => state.HOME?.pageSettings)
	const communityData = communityContent?.section_4;


	const closeModal = () => {
		videoModal && localStorage.setItem("videoModal", "true")
		setOpenModal(false)
	}

	const meta_tags = {
		title: "Home",
		description: "The offshore wind industry is growing rapidly, and there are many opportunities for qualified workers. But how do you know if a career in offshore wind is right for you? And how do you get the training and education you need to get started?",
		keywords: "energy, offshore, wind industry",
		image: WebConstant?.IMAGE_URL + HOME?.pageSettings?.banner?.banner_logo,
		url: "/"
	}

	return (
		<div className="webBody">
			<HelmetComponent meta_tags={meta_tags} /> {/* Add meta tags here */}
			{/* show spinner when api data  is fetched */}
			{HOME.appLoading ? <PageSpinner /> :
				<>
					<Banner />
					{HOME?.pageSettings?.options?.counter_visible === "1" && <Counter />}
					<EnergyRaining />
					<ToolProcess />
					<Achive />
					<div className="community_section_description">
						{communityData?.description &&
							<div className="container">
								<div dangerouslySetInnerHTML={contentMarkupHeader("subtitle", communityData.description)}></div>
							</div>
						}
						{communityData?.main_button_title &&
							<div className="community_section_contactus">

								<Link to={communityData?.main_button_link} target="_blank">
									<Button className="common_button green_border_common_btn"><span>{communityData.main_button_title}</span></Button>
								</Link>
							</div>

						}
					</div>
					<Community />
					<Testimonial />

					{HOME?.pageSettings?.options?.section_5_visible === "1" && <TrainingSolutions />}
					<Footer />
					
					{/*Show Video Modal when component is loaded */}
					{(modalVideo && storageVideoModal !== "true" && HOME?.pageSettings?.options?.show_popup_video === "1") && (
						<div className="videoModal">
							<Modal
								wrapClassName="videoModal"
								open={openModal}
								onCancel={() => closeModal()}
								width={"800px"}
								centered
								footer={<div><Checkbox onChange={(e: CheckboxChangeEvent) => {
									setVideoModal(e.target.checked);
								}}>{HOME?.pageSettings?.options?.video_popup_confirmation_message}</Checkbox></div>}
							>
								<iframe
									width="100%"
									height="100%"
									src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
									title="YouTube Video"
									// frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								></iframe>

							</Modal>
						</div>
					)}
				</>
			}
		</div >
	)
};

export default HomePage;
