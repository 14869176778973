import { Button, Col, Form, Row } from 'antd'
import React from 'react'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { rules } from './Rules'
import { useNavigate, useParams } from 'react-router-dom'
import { assignErrorToInput } from '../../../../store/api'
import { updatePassword } from '../../../../store/AuthSlice'
import { AppDispatch } from '../../../../store/app'
import { useDispatch } from 'react-redux'

/**
 * Renders the ChangePassword component.
 *
 * @return {JSX.Element} The rendered ChangePassword component.
 */
const ChangePassword = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const { otp }: any = useParams();
    const dispatch: AppDispatch = useDispatch();


    const resetPassword = (data: any) => {
        data.otp = otp
        localStorage.removeItem('token')
        dispatch(updatePassword(otp, data)).then(() => {
            navigate('/login');
        }).catch((error: any) => {
            assignErrorToInput(form, error?.STATUS);
        })
    }
    return (
        <section className='webLoginSection webRegisterSection'>
            <h2 className="subtitle">Enter new Password</h2>
            <div className='webLoginBox'>
                <FormBox form={form} onFinish={resetPassword}>
                    <Row>
                        <Col sm={24} xs={24} md={24} xxl={24}>
                            <InputBox.Password
                                name="password"
                                placeholder='Password'
                                rules={rules.password()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} xs={24} md={24} xxl={24}>
                            <InputBox.Password
                                name="password_confirmation"
                                placeholder='Confirm Password'
                                rules={rules.password_confirmation()}
                            />
                        </Col>
                    </Row>
                    <div className='logInBtn'>
                        <Button className='log_in_button secondry_btn_log_page' htmlType='submit'>
                            SUBMIT
                        </Button>
                    </div>
                </FormBox>
            </div>
        </section>
    )
}

export default ChangePassword