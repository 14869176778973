import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { BreadcrumbComponentItemTypes, BreadcrumbComponentProps } from "../../../config/InterfacesAndTypes";

/**
 * Renders a breadcrumb component for dashboard pages.
 *
 * @param {BreadcrumbComponentProps} props - The props object containing the items to be rendered.
 * @param {BreadcrumbComponentItemTypes[]} props.items - An array of items to be rendered as breadcrumbs.
 * @return {JSX.Element} The breadcrumb component.
 */
const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({ items }) => {
    return (
        <Breadcrumb className="ph-gutter">
            {items?.map((item: BreadcrumbComponentItemTypes, index: number) => (
                <Breadcrumb.Item key={index}>
                    {item.link ? <Link to={item.link}>{item.name}</Link> : item.name}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};

export default BreadcrumbComponent;