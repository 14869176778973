import React from 'react'
import "../Style/cmsPage.less"
import { WebConstant } from '../../webConstant/WebConstant'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/app'
import { useNavigate } from 'react-router-dom'
import HelmetComponent from '../../CommonComponent/HelmetComponent'

/**
 * Renders the CmsComponent for terms and conditions page, privacy policy page, and about us page.
 *
 * @param {Object} props - The props object containing the page data and logo.
 * @param {Object} props.data - The data object containing the page information.
 * @param {string} props.data.meta_title - The title of the page.
 * @param {string} props.data.meta_description - The description of the page.
 * @param {string} props.data.meta_Keyword - The keywords for the page.
 * @param {string} props.data.slug - The slug of the page.
 * @param {string} props.data.title - The title of the page.
 * @param {string} props.data.description - The description of the page.
 * @param {string} props.logo - The URL of the logo image.
 * @return {JSX.Element} The rendered CmsComponent.
 */
const CmsComponent = (props: any) => {
    const pageData = props?.data;
    const logo = useSelector((state: RootState) => state.HOME.pageSettings?.banner?.banner_logo)
    const navigate = useNavigate()
    const meta_tags = {
        title: pageData?.meta_title,
        description: pageData?.meta_description,
        keywords: pageData?.meta_Keyword,
        image: WebConstant?.IMAGE_URL + logo,
        url: "/"
    }
    return (
        <>
            <HelmetComponent meta_tags={meta_tags} />
            <section className='cmsMainPage'>
                <div className='cmsPages'>
                    <div className="container">
                        <div className='logoImg'>
                            <img className="logo" onClick={() => navigate("/")} src={WebConstant?.IMAGE_URL + logo} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="content_section">
                        <h2 className='heading'>{pageData.title}</h2>
                    </div>
                    <div className='cmsInnerContent' dangerouslySetInnerHTML={{ __html: pageData.description }}></div>
                    {/* <div>{pageData.meta_description}</div>
                    <div>{pageData.slug}</div>
                    <div>{pageData.meta_title}</div> */}
                </div>
            </section>
        </>
    )
}

export default CmsComponent