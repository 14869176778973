import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/app'
import { Button } from 'antd'
import { contentMarkup, contentMarkupWithClass } from '../../../../config/Global'

/**
 * Renders the ToolProcess component which displays a section about Career Discovery Process.
 *
 * @return {JSX.Element} The ToolProcess component
 */
const ToolProcess = () => {
    const toolProcessContent = useSelector((state: RootState) => state.HOME?.pageSettings)
    const toolProcessData = toolProcessContent?.section_1
    const bannerContent = useSelector((state: RootState) => state.HOME?.pageSettings)
    const bannerData = bannerContent?.banner;

    const processBox = (number: string, title: string, content: string) => {
        return (<div className='skill-section'>
            <div className='skill-section-number'>{number ?? null}
                {title && <h4 className='skill-title'>{title ?? null}</h4>}
            </div>

            {/* {content && <p className='skill-content'>{content ?? null}</p>} */}
            {content && <div dangerouslySetInnerHTML={contentMarkupWithClass("skill-content", content)}></div>}
        </div>)
    }
    return (
        <section className='process_section'>
            <div className="container">
                <div className='process_section_inner'>
                    <div className='content_section'>
                        {toolProcessData?.heading &&
                            <h2 className='heading'>
                                {toolProcessData.heading}
                            </h2>
                        }
                        {toolProcessData?.description &&
                            <div dangerouslySetInnerHTML={contentMarkup(toolProcessData?.description)}></div>
                        }
                    </div>
                    <div className='interest-skill-result'>
                        {processBox(toolProcessData?.number_1, toolProcessData?.title_1, toolProcessData?.content_1)}
                        {processBox(toolProcessData?.number_2, toolProcessData?.title_2, toolProcessData?.content_2)}
                        {processBox(toolProcessData?.number_3, toolProcessData?.title_3, toolProcessData?.content_3)}
                    </div>
                    {bannerData?.first_button_title &&
                        <div className='interest-skill-btn'>
                            <a href={bannerData?.first_button_url}>
                                <Button className="common_Button green_border_common_btn"><span>{bannerData.first_button_title}</span></Button>
                            </a>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default ToolProcess