import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import AuthSliceReducer from "./AuthSlice";
import CommonSliceReducer from "./CommonSlice";
import HomeSliceReducer from "../views/webPages/HomePage/utils/slice";
import WebProfileSlice from "../views/webPages/WebDashBoard/Components/Utils/slice";
import CmsPageSlice from "../views/webPages/CmsPages/Utils/Slice"

const store = configureStore({
  reducer: {
    AUTH: AuthSliceReducer,
    COMMON: CommonSliceReducer,
    HOME: HomeSliceReducer,
    WEBPROFILE:WebProfileSlice,
    CMSPAGES:CmsPageSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<any>
>;

export default store; // Export the store as the default export
