import React from 'react'
import { Helmet } from "react-helmet-async";

type Props = {
    meta_tags: {
        title: string;
        description: string;
        keywords: string;       
        image: string;       
        url: string;       
    };
};

/**
 * Renders the HelmetComponent with the provided meta tags to improve SEO performance.
 *
 * @param {Props} props - The props object containing the meta tags.
 * @param {string} props.meta_tags.title - The title of the page.
 * @param {string} props.meta_tags.description - The description of the page.
 * @param {string} props.meta_tags.keywords - The keywords for the page.
 * @param {string} props.meta_tags.image - The URL of the image for the page.
 * @param {string} props.meta_tags.url - The canonical URL of the page.
 * @return {JSX.Element} The rendered HelmetComponent.
 */
const HelmetComponent = (props: Props) => {
    
    const {
        meta_tags: { title, description, keywords, image,url },
    } = props;
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{title ?? "Energy Career Navigator"}</title>
                <meta
                    name="description"
                    content={description ?? "The offshore wind industry is growing rapidly, and there are many opportunities for qualified workers. But how do you know if a career in offshore wind is right for you? And how do you get the training and education you need to get started?"}
                />
                <meta name="keywords" content={keywords ?? "energy, offshore, wind industry"} />
                <link rel="canonical" href={url ??"/"} />

                {/* Open Graph Metadata */}

                <meta property="og:title" content={title??"Energy Career Navigator"} />
                <meta property="og:url" content={url ?? "/"} />
                <meta property="og:image" content={image} />
                <meta
                    property="og:site_name"
                    content="Energy Career Navigator"
                />
                <meta property="og:type" content="website" />
            </Helmet>
        </>
    );
}

export default HelmetComponent