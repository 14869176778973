import React from 'react'
import { Button, Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/app'
import { WebConstant } from '../../webConstant/WebConstant'
import {  contentMarkupWithClass } from '../../../../config/Global'

/**
 * Renders the community section of the homepage with a title, subtitle, and description
 * if is_visible is true from admin panel.
 *
 * @return {JSX.Element | null} The community section JSX element or null if the data is not visible.
 */
const Community = () => {
    const communityContent = useSelector((state: RootState) => state.HOME?.pageSettings)
    const communityData = communityContent?.section_4;

        return communityData && (communityData.is_visible === "1") ? (
        <section className='community-section'>
            <div className='container'>
                <div className='content_section'>
                    {communityData?.subtitle &&
                        <h4>
                            {communityData.subtitle}
                        </h4>
                    }

                    {communityData?.title &&
                        <h2 className='heading'> {communityData.title}</h2>
                    }
                    
                </div>
                <Row className='community-content' align={"stretch"}>
                    <>
                        <Col sm={24} md={12} lg={12} xxl={12} className='communty-descreption'>
                            {communityData?.item_title_1 &&
                                <h4 className="subtitle">{communityData.item_title_1}</h4>
                            }
                            {communityData?.item_content_1 &&

                                <div dangerouslySetInnerHTML={contentMarkupWithClass("communty-content", communityData.item_content_1)}></div>

                            }
                            {communityData?.button_title_1 &&
                                <a href={communityData?.button_url_1}>
                                    <Button className="common_button green_border_common_btn"><span>{communityData.button_title_1}</span></Button>
                                </a>
                            }
                        </Col>

                        <Col sm={24} md={12} lg={12} xxl={12}>
                            {communityData?.image_url_1 &&
                                <img src={WebConstant?.IMAGE_URL + communityData.image_url_1} alt='community_1' className='achiveImg' />
                            }
                        </Col>
                    </>
                </Row>
                {(communityData?.item_title_2 || communityData?.item_content_2 || communityData?.button_title_2) &&
                    <Row className='community-content' align={"stretch"}>
                        <>
                            <Col sm={24} md={12} lg={12} xxl={12} className='communty-descreption'>
                                {communityData?.item_title_2 &&
                                    <h4 className="subtitle">{communityData.item_title_2}</h4>
                                }
                                {communityData?.item_content_2 &&

                                    <div dangerouslySetInnerHTML={contentMarkupWithClass("communty-content", communityData.item_content_2)}></div>
                                }
                                {communityData?.button_title_2 &&
                                    <a href={communityData?.button_url_2}>
                                        <Button className="common_button green_border_common_btn"><span>{communityData.button_title_2}</span></Button>
                                    </a>
                                }
                            </Col>

                            <Col sm={24} md={12} lg={12} xxl={12}>
                                {communityData?.image_url_2 &&

                                    <img src={WebConstant?.IMAGE_URL + communityData.image_url_2} alt='community_2' className='achiveImg' />

                                }
                            </Col>
                        </>
                    </Row>
                }
                {(communityData?.item_title_3 || communityData?.item_content_3 || communityData?.button_title_3) &&
                    <Row className='community-content' align={"stretch"}>
                        <>
                            <Col sm={24} md={12} lg={12} xxl={12} className='communty-descreption'>
                                {communityData?.item_title_3 &&
                                    <h4 className="subtitle">{communityData.item_title_3}</h4>
                                }
                                {communityData?.item_content_3 &&
                                    <div dangerouslySetInnerHTML={contentMarkupWithClass("communty-content", communityData.item_content_3)}></div>
                                }
                                {communityData?.button_title_3 &&
                                    <a href={communityData?.button_url_3}>
                                        <Button className="common_button green_border_common_btn"><span>{communityData.button_title_3}</span></Button>
                                    </a>
                                }
                            </Col>

                            <Col sm={24} md={12} lg={12} xxl={12}>
                                {communityData?.image_url_3 &&
                                    <img src={WebConstant?.IMAGE_URL + communityData.image_url_3} alt='community_3' className='achiveImg' />

                                }
                            </Col>
                        </>
                    </Row>
                }
            </div>
        </section>
    ) : null
}

export default Community