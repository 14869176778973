import React from 'react'
import { Col, Row } from 'antd';
import windmilGif from "../../../../assets/webimage/Background_Wind_Mill.gif"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/app';
import { WebConstant } from '../../webConstant/WebConstant';
import { contentMarkup, contentMarkupWithClass } from '../../../../config/Global';

/**
 * Renders the component for section 2 that contains the description about energy and image.
 *
 * @returns {JSX.Element | null} The rendered component.
 */
const EnergyRaining = () => {
    const EnergyRainingContent = useSelector((state: RootState) => state.HOME?.pageSettings)
    const EnergyRainingData = EnergyRainingContent?.section_2

    return EnergyRainingContent ? (
        <section className="energyRainingSection overflowVisible">
            <div className='container'>
                <Row gutter={0} align={'middle'} className='enery-training'>
                    {EnergyRainingData?.image &&
                        <Col xs={24} md={10} lg={7}>
                            <img src={WebConstant?.IMAGE_URL + EnergyRainingData.image} alt='windmills' className='grayBgSectionImg' />
                        </Col>
                    }
                    <Col xs={24} md={14} lg={17}>
                        <div className="grayBgSection">
                            {EnergyRainingData?.title && <div dangerouslySetInnerHTML={contentMarkup(EnergyRainingData.title)}></div>}
                            {EnergyRainingData?.content && <div dangerouslySetInnerHTML={contentMarkupWithClass("", EnergyRainingData.content)}></div>}
                        </div>
                        <img src={windmilGif} alt='windmilGif' className='windmilGif' />
                    </Col>

                </Row>
            </div>
        </section>
    ) : null
}

export default EnergyRaining