import { validations } from "../../../../config/validations/validations";


export const rules: any = {
	password: (field?: any) => [
		validations.required.text(field),
		validations.pattern.password(field),

	],
	password_confirmation: (field?: any) => [
		validations.required.text(field),
		// validations.pattern.password(field),
		({ getFieldValue }: any) => ({
			validator() {
				const password = getFieldValue("password");
				const password_confirmation = getFieldValue("password_confirmation");

				if (password_confirmation) {
					if (password.trim() === password_confirmation) {
						return Promise.resolve();
					} else {
						return Promise.reject("Password does not match.");
					}
				}
				return Promise.resolve();
			},
		}),
	],
};