import React from 'react'
import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/app'
import { WebConstant } from '../../webConstant/WebConstant'
import { Link } from 'react-router-dom'
import { contentMarkup, contentMarkupWithClass } from '../../../../config/Global'
	
/**
 * Renders the component which displays IWNL ENERGY COMPREHENSIVE TRAINING SOLUTIONS section 
 * on the home page.
 *
 * @return {JSX.Element | null} The rendered Training Solutions component.
 */
const TrainingSolutions = () => {
	const trainingSolutionsContent = useSelector((state: RootState) => state.HOME?.pageSettings)
	const trainingSolutionsData = trainingSolutionsContent?.section_5;
	const section_5 = (img: any, title: any, content: any) => {
		return (
			<div className='subSections'>
				{img &&
					<img src={WebConstant?.IMAGE_URL + img} alt='community_1' className='achiveImg' />
				}
				{title && <h5 className='training_solutions_box_heading'>{title}</h5>}
				{content && <div dangerouslySetInnerHTML={contentMarkupWithClass("training_solutions_box_discription", content)}></div>}
			</div>
		)
	}
	return trainingSolutionsData ? (
		<section className='training_solutions'>
			<div className="container">
				<div className='content_section'>
					{trainingSolutionsData?.title &&
						<h2 className='training_solutions_heading'>
							{trainingSolutionsData.title}
						</h2>
					}

					{trainingSolutionsData?.description &&
						<div dangerouslySetInnerHTML={contentMarkup(trainingSolutionsData.description)}></div>
					}
				</div>
				<div className='box'>
					<Row gutter={0} align={"stretch"}>
						<Col sm={24} md={12} lg={8} xxl={8} className='mainBox'>
							{trainingSolutionsData?.item_link_1 !== null ?
								<Link to={trainingSolutionsData?.item_link_1 ? trainingSolutionsData?.item_link_1 : null} target='_blank'>
									{section_5(trainingSolutionsData.image_url_1, trainingSolutionsData.item_title_1, trainingSolutionsData.item_content_1)}
								</Link> :
								section_5(trainingSolutionsData.image_url_1, trainingSolutionsData.item_title_1, trainingSolutionsData.item_content_1)
							}
						</Col>
						<Col sm={24} md={12} lg={8} xxl={8} className='mainBox'>
							{trainingSolutionsData?.item_link_2 !== null ?
								<Link to={trainingSolutionsData?.item_link_2 ? trainingSolutionsData?.item_link_2 : null} target='_blank'>
									{section_5(trainingSolutionsData.image_url_2, trainingSolutionsData.item_title_2, trainingSolutionsData.item_content_2)}
								</Link> :
								section_5(trainingSolutionsData.image_url_2, trainingSolutionsData.item_title_2, trainingSolutionsData.item_content_2)
							}
						</Col>
						<Col sm={24} md={12} lg={8} xxl={8} className='mainBox'>

							{trainingSolutionsData?.item_link_3 !== null ?
								<Link to={trainingSolutionsData?.item_link_3 ? trainingSolutionsData?.item_link_3 : null} target='_blank'>
									{section_5(trainingSolutionsData.image_url_3, trainingSolutionsData.item_title_3, trainingSolutionsData.item_content_3)}
								</Link> :
								section_5(trainingSolutionsData.image_url_3, trainingSolutionsData.item_title_3, trainingSolutionsData.item_content_3)}
						</Col>
						<Col sm={24} md={12} lg={8} xxl={8} className='mainBox'>
							{trainingSolutionsData?.item_link_4 !== null ?
								<Link to={trainingSolutionsData?.item_link_4 ? trainingSolutionsData?.item_link_4 : null} target='_blank'>
									{section_5(trainingSolutionsData.image_url_4, trainingSolutionsData.item_title_4, trainingSolutionsData.item_content_4)}
								</Link>
								:
								section_5(trainingSolutionsData.image_url_4, trainingSolutionsData.item_title_4, trainingSolutionsData.item_content_4)
							}
						</Col>
						<Col sm={24} md={12} lg={8} xxl={8} className='mainBox'>
							{trainingSolutionsData?.item_link_5 !== null ?
								<Link to={trainingSolutionsData?.item_link_5 ? trainingSolutionsData?.item_link_5 : null} target='_blank'>
									{section_5(trainingSolutionsData.image_url_5, trainingSolutionsData.item_title_5, trainingSolutionsData.item_content_5)}
								</Link>
								: section_5(trainingSolutionsData.image_url_5, trainingSolutionsData.item_title_5, trainingSolutionsData.item_content_5)
							}
						</Col>
						<Col sm={24} md={12} lg={8} xxl={8} className='mainBox'>
							{trainingSolutionsData?.item_link_6 !== null ?
								<Link to={trainingSolutionsData?.item_link_6 ? trainingSolutionsData?.item_link_6 : null} target='_blank'>
									{section_5(trainingSolutionsData.image_url_6, trainingSolutionsData.item_title_6, trainingSolutionsData.item_content_6)}
								</Link>
								:
								section_5(trainingSolutionsData.image_url_6, trainingSolutionsData.item_title_6, trainingSolutionsData.item_content_6)
							}
						</Col>
					</Row>
				</div>
			</div>
		</section>
	) : null
}

export default TrainingSolutions