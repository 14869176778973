import { CONSTANT } from "../../config/Constant";

export const loadMapApi = () => {
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${CONSTANT.MAPS_API_KEY}&libraries=places%2Cgeometry%2Cdrawing%2Cvisualization&callback=__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__`;
  const scripts = document.getElementsByTagName("script");
  // Go through existing script tags, and return google maps api tag when found.
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === mapsURL) {
      return scripts[i];
    }
    if (scripts[i].src.indexOf(mapsURL) === 0) {
      return false; //scripts[i];
    }
  }

  const googleMapScript = document.createElement("script");
  googleMapScript.src = mapsURL;
  googleMapScript.async = true;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};
