import React from 'react'
import { Col, Row } from 'antd'
import { RootState } from '../../../../store/app'
import { useSelector } from 'react-redux'
import { WebConstant } from '../../webConstant/WebConstant'
import { contentMarkup, contentMarkupWithClass } from '../../../../config/Global'

/**
 * Renders the Archive component for the Home Page contains benefits of IWNL.
 *
 * @return {JSX.Element | null} The rendered Archive component or null if archive Data is falsy.
 */
const Achive = () => {
    const achiveContent = useSelector((state: RootState) => state.HOME?.pageSettings)
    const achiveData = achiveContent?.section_3;

    return achiveData ? (
        <section className='achive-section keyAnim'>
            <div className='container'>
                <div className='content_section'>
                    {achiveData?.subtitle &&
                        <h4>
                            {achiveData.subtitle}
                        </h4>
                    }
                    {achiveData?.title &&
                        <h2 className='heading'> 
                            {achiveData.title}
                        </h2>
                    }
                    {achiveData?.description &&
                        <div dangerouslySetInnerHTML={contentMarkup(achiveData.description)}>
                        </div>
                    }
                </div>
                <Row gutter={20} className='achive_block'>
                    <Col sm={24} md={12} xl={6} >
                        <div className='achive-block'>
                            {achiveData?.image_url_1 &&
                                <img src={WebConstant?.IMAGE_URL + achiveData?.image_url_1} alt='achive_1' className='achiveImg' />
                            }

                            <div className='achiveCardContent'>
                                {achiveData?.order_number_1 &&
                                    <span className="achiveCardNumber">{achiveData.order_number_1}</span>
                                }
                                <div className='content'>
                                    {achiveData?.item_title_1 &&
                                        <h5 className='achiveCardTitle'>{achiveData.item_title_1}</h5>
                                    }
                                    {
                                        achiveData?.item_content_1 && 
                                        <div className='achiveCardDescription' dangerouslySetInnerHTML={contentMarkupWithClass("", achiveData.item_content_1)}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={12} xl={6} >
                        <div className='achive-block'>
                            {achiveData?.image_url_2 &&
                                <img src={WebConstant?.IMAGE_URL + achiveData.image_url_2} alt='achive_2' className='achiveImg' />
                            }

                            <div className='achiveCardContent'>
                                {achiveData?.order_number_2 &&
                                    <span className="achiveCardNumber">{achiveData.order_number_2}</span>
                                }
                                <div className='content'>
                                    {achiveData?.item_title_2 &&
                                        <h5 className='achiveCardTitle'>{achiveData.item_title_2}</h5>
                                    }
                                    {
                                        achiveData?.item_content_2 && 
                                        <div className='achiveCardDescription' dangerouslySetInnerHTML={contentMarkupWithClass("", achiveData.item_content_2)}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={12} xl={6} >
                        <div className='achive-block'>
                            {achiveData?.image_url_3 &&
                                <img src={WebConstant?.IMAGE_URL + achiveData.image_url_3} alt='achive_3' className='achiveImg' />
                            }

                            <div className='achiveCardContent'>
                                {achiveData?.order_number_3 &&
                                    <span className="achiveCardNumber">{achiveData.order_number_3}</span>
                                }
                                <div className='content'>
                                    {achiveData?.item_title_3 &&
                                        <h5 className='achiveCardTitle'>{achiveData.item_title_3}</h5>
                                    }
                                    {
                                        achiveData.item_content_3 && 
                                        <div className='achiveCardDescription' dangerouslySetInnerHTML={contentMarkupWithClass("", achiveData.item_content_3)}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={12} xl={6} >
                        <div className='achive-block'>
                            {achiveData?.image_url_4 &&
                                <img src={WebConstant?.IMAGE_URL + achiveData?.image_url_4} alt='achive_3' className='achiveImg' />
                            }
                            <div className='achiveCardContent'>
                                {achiveData?.order_number_4 &&
                                    <span className="achiveCardNumber">{achiveData.order_number_4}</span>
                                }
                                <div className='content'>
                                    {achiveData?.item_title_4 &&
                                        <h5 className='achiveCardTitle'>{achiveData.item_title_4}</h5>
                                    }
                                    {
                                        achiveData?.item_content_4 && 
                                        <div className='achiveCardDescription' dangerouslySetInnerHTML={contentMarkupWithClass("", achiveData.item_content_4)}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    ) : null
}

export default Achive