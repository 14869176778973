import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/app";
import { WebConstant } from "../../webConstant/WebConstant";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider } from "antd";

/**
 * Renders the Banner component which displays a banner section with a background image, 
 * logo, title, subtitle, and buttons. The banner section also contains a login or dashboard button 
 * depending on the user's authentication status.
 *
 * @return {JSX.Element} The Banner component
 */
const Banner = () => {
	const bannerContent = useSelector((state: RootState) => state.HOME?.pageSettings)
	const bannerData = bannerContent?.banner;
	const Auth = useSelector((state: RootState) => state.AUTH);
	const Navigate = useNavigate()
	const login = () => {
		Navigate("/login")
	}
	const register = () => {
		Navigate("/register")
	}
	const dashboard = () => {
		Navigate("/dashboard")
	}
	return (
		<section className="bannerSection" style={{ backgroundImage: `url(${WebConstant?.IMAGE_URL}${bannerData?.banner_image_url})` }}> {/*style={{ background: bannareData.data.banner.bannerBGImage }}*/}
			<div className="banner">
				<div className="container">
					<div className="bannerBtns">
						<div className="loginBtn">
							<Link to="https://www.iwnlenergy.com" target="_blank">
								<Button className="common_Button web_btn_secondary" ><span>Home</span></Button>
							</Link>
						</div>
						{!Auth.token ?
							<div className="loginBtn">
								<Button className="common_Button web_btn_secondary" onClick={login}><span>Log In</span></Button>
								{(bannerContent?.options?.showRegister === "1") &&
									<>
										<Divider type="vertical" className="afterLoginBtnDivider" />
										<Button className="common_Button web_btn_secondary" onClick={register}><span>Register</span></Button>
									</>
								}
							</div>
							: <Button className="common_Button web_btn_secondary" onClick={dashboard}><span>Dashboard</span></Button>
						}
					</div>
					<div className="content">
						{bannerData?.banner_logo &&
							<div className="logoImg">
								<Link to="https://www.iwnlenergy.com" target="_blank">
									<img className="logo" src={WebConstant?.IMAGE_URL + bannerData.banner_logo} alt="logo" />
								</Link>
							</div>
						}
						{bannerData?.subtitle &&
							<h4 className="subtitle">{bannerData.subtitle}</h4>
						}
						{bannerData?.title &&
							<h1 className="title">{bannerData.title}</h1>
						}
						{bannerData?.first_button_title &&

							<Link to={bannerData?.first_button_url}>
								<Button className="common_Button web_btn_primary"><span>{bannerData.first_button_title}</span></Button>
							</Link>

						}

					</div>
				</div>
			</div>
		</section>
	)
};

export default Banner;
