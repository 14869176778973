import React, { useEffect, useState } from 'react'
import { AppDispatch, RootState } from '../../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { ViewIntersetExam, finishExam, submitAnswer, tierOneSubmitAnswer } from '../Utils/slice'
import { useNavigate, useParams } from 'react-router-dom'
import InterestAssessmentForm from './InterestAssessmentCommon/InterestAssessmentForm'
import { Form } from 'antd'
import PageSpinner from '../../../../../components/PageSpinner/PageSpinner'
import dayjs from 'dayjs'

/**
 * Renders the component for pending Interest Discovery Exam.
 *
 * @return {JSX.Element} The rendered component.
 */
const InterestAssessmentPending = () => {
    const dispatch: AppDispatch = useDispatch()
    const { id }: any = useParams()
    const allsteps = useSelector((state: RootState) => state.WEBPROFILE.bothExamData)
    const [current, setCurrent] = useState(Number(0));
    const [form] = Form.useForm();
    const navigate = useNavigate()
    // const [formatedTime, setFormatedTime] = useState<any>()
    // const [totalSeconds, setTotalSeconds] = useState<any>(0);
    // const [isActive, setIsActive] = useState(true);
    const [loading, setLoading] = useState(true)
    // const currentDate = dayjs().format('YYYY-MM-DD')
    const [btnLoading, setBtnLoading] = useState(false)
    const [updateSteps, setUpdateSteps] = useState<any>([])
    const decodedDataId = atob(id);
    
    // Set the answer of answered questions in the exam and to set remaining questions in the exam
    useEffect(() => {
        dispatch(ViewIntersetExam(decodedDataId))
            .then((data: any) => {
                // if (data?.data?.spend_time) {
                //     const getTime = dayjs(data.data.spend_time).format("hh:mm:ss")
                //     const [hoursStr, minutesStr, secondsStr]: any = getTime.split(":").map(Number);
                //     const hours = parseInt(hoursStr) === 12 ? 0 : parseInt(hoursStr);
                //     const minutes = parseInt(minutesStr);
                //     const seconds = parseInt(secondsStr);
                //     const totalNumber = hours * 3600 + minutes * 60 + seconds;
                //     setTotalSeconds(totalNumber + 1)
                // }
                // for direct pending question

                if (data?.data?.attends?.length > 0) {
                    const questions = data.data.questions
                    const attendsQue = data.data.attends
                    const findWithoutAnswer = questions?.filter((item: any) => !attendsQue.some((item2: any) => item.id === item2.question_id));
                    const findIndex = questions?.findIndex((item: any) => item?.id === findWithoutAnswer?.[0]?.id)
                    setCurrent(findIndex)
                    // const lastArrayData = findWithoutAnswer && findWithoutAnswer[findWithoutAnswer?.length - 1]
                }
                if (data.data.attends) {
                    data.data.attends.map((item: any) => form.setFieldValue(item.question_id, item?.answer))
                }
                if (data?.data?.questions?.length > 36) {
                    var newArray: any = []

                    const firstThirtySix = data?.data?.questions?.slice(0, 36).map((item: any) => ({
                        key: item?.id,
                        title: item?.question,
                        content: item?.answers,
                        question_id: item?.id,
                        options: item?.answer_options,
                        disabled: true
                    }));


                    const lastFourteen = data?.data?.questions?.slice(36).map((item: any) => ({
                        key: item?.id,
                        title: item?.question,
                        content: item?.answers,
                        question_id: item?.id,
                        options: item?.answer_options,
                        disabled: false
                    }));

                    newArray.push([...firstThirtySix, ...lastFourteen])

                    const steps = newArray[0].map((item: any, index: number) => ({
                        key: item?.key,
                        title: item?.title,
                        content: item?.content,
                        question_id: item?.question_id,
                        options: item?.options,
                        index: index + 1,
                        disabled: item.disabled
                    }));
                    setUpdateSteps(steps)
                } else {
                    const steps = data?.data?.questions?.map((item: any, index: number) => ({
                        key: item?.id,
                        title: item?.question,
                        content: item?.answers,
                        question_id: item?.id,
                        options: item?.answer_options,
                        index: index + 1,
                        disabled: true
                    }));
                    setUpdateSteps(steps)
                }
                setLoading(false)

            })
            .catch((e: any) => e)
    }, [])

    const itemsPerPage = 6;
    const startIndex = current;
    const endIndex = current + itemsPerPage;
    const getFirstSix = updateSteps?.length > 0 && updateSteps?.slice(startIndex, endIndex);
    const lastArrayData = getFirstSix && getFirstSix[getFirstSix?.length - 1]


    // this useEffect for set value in form 
    // useEffect(() => {
    //     if (steps?.length > 0 && allsteps) {
    //         const ans = allsteps?.attends?.find((item: any) => {

    //             return (
    //                 item?.question_id === steps[current]?.question_id
    //             )
    //         })
    //         if (ans !== undefined) {
    //             form.setFieldValue(`answer${steps[current].question_id}`, parseInt(ans?.answer))
    //         }
    //     }

    // }, [current, allsteps])


    // this useEffect for time calculation 

    // const onFocus = () => {
    //     setIsActive(true);
    // };
    // const onBlur = () => {
    //     setIsActive(false);
    // }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (isActive) {
    //             setTotalSeconds((prevTotalSeconds: any) => prevTotalSeconds + 1);
    //         }
    //     }, 1000);


    //     window.addEventListener("focus", onFocus);
    //     window.addEventListener("blur", onBlur);
    //     return () => {
    //         clearInterval(interval);
    //         window.removeEventListener("focus", onFocus);
    //         window.removeEventListener("blur", onBlur);
    //     };
    // }, [isActive]);


    // this function for incress hours and min upone second
    // const formatTime = () => {
    //     const hours = Math.floor(parseInt(totalSeconds) / 3600);
    //     const minutes = Math.floor((parseInt(totalSeconds) % 3600) / 60);
    //     const remainingSeconds = parseInt(totalSeconds) % 60;

    //     const hoursStr = String(hours).padStart(2, '0');
    //     const minutesStr = String(minutes).padStart(2, '0');
    //     const secondsStr = String(remainingSeconds).padStart(2, '0');
    //     setFormatedTime(`${hoursStr}:${minutesStr}:${secondsStr}`)
    //     return `${hoursStr}:${minutesStr}:${secondsStr}`;
    // };
    
    const validateForm = () => {
        form.validateFields()
    };

    // Button to move to next page and fetch remaining questions after 36 questions
    const nextButton = (data: any) => {
        const newArray = [];
        // Iterate over the key-value pairs and push them into the new array
        for (const [ques_id, ans_id] of Object.entries(data)) {
            newArray.push({ ques_id, ans_id });
        }
        data.answers = newArray
        data.assessment_type = "2"
        const keysToRemove = getFirstSix.map((item: any) => item.question_id);
        keysToRemove.forEach((key: any) => {
            delete data[key];
            return data
        });
        setBtnLoading(true)

        if (lastArrayData?.index === 50) {
            completeExam(data)
        } else if (updateSteps.length > 36 && lastArrayData.disabled) {
            setBtnLoading(false)
            setCurrent(current + itemsPerPage);
        } else {
            dispatch(submitAnswer(decodedDataId, data))
                .then(() => {
                    if (allsteps?.attends?.length < 36 && lastArrayData?.index === 36 && updateSteps.length != 50) {
                        dispatch(tierOneSubmitAnswer(decodedDataId)).then((res: any) => {
                            const steps = res?.data?.questions.map((item: any, index: number) => ({
                                key: item?.id,
                                title: item?.question,
                                content: item?.answers,
                                question_id: item?.id,
                                options: item?.answer_options,
                                index: lastArrayData.index + index + 1,
                                disabled: false
                            }));
                            setUpdateSteps([...updateSteps, ...steps])
                            setCurrent(current + itemsPerPage);
                            setBtnLoading(false)
                        })
                            .catch((e: any) => {
                                e
                                navigate("/dashboard")
                            })
                    } else {
                        setCurrent(current + itemsPerPage);
                        setBtnLoading(false)
                    }
                    
                })
                .catch((e: any) => {
                    e
                    setBtnLoading(false)
                })
        }

    }

    // For scroll to the top after clicking on next button
    useEffect(()=>{
        if(!btnLoading){
        if ((document.body.clientHeight-4) > window.innerHeight) {
                       window.scrollTo({ top: 0, behavior: "smooth" });
                   }}
       },[nextButton,btnLoading])

    // For submitting the exam after all the questions are answered
    const completeExam = (data: any) => {
        data.current_time = dayjs().format('YYYY-MM-DD HH:mm:ss'),
            dispatch(submitAnswer(decodedDataId, data)).then(() => {
                dispatch(finishExam(decodedDataId,data)).then(() => {
                    navigate("/dashboard")
                })
                    .catch((e: any) => {
                        e
                    navigate("/dashboard")
                        setBtnLoading(false)
                    })
            }).catch((e: any) => {
                e
                setBtnLoading(false)
            })

    }

    return !loading ? (
        <InterestAssessmentForm
            validateForm={validateForm}
            steps={updateSteps}
            nextButton={nextButton}
            form={form}
            current={current}
            setCurrent={setCurrent}
            id="InterestAssessmentFormPending"
            getFirstSix={getFirstSix}
            lastArrayData={lastArrayData}
            // formatTime={formatTime}
            btnLoading={btnLoading}
        />
    ) : <PageSpinner />
}

export default InterestAssessmentPending