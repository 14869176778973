import React, { useEffect, useState } from 'react'
import { FormBox } from '../../../../../../components/AntdAddons';
import { Button, Col, Row, Tooltip, Form, Radio, Progress } from 'antd';
import BreadcrumbComponent from '../../../../CommonComponent/BreadcrumbComponent';
import { InterestAssessmentBreadcrumb } from '../../../../../../config/BreadcrumbConfig';
import { ReactComponent as StronglyAgree } from "../../../../../../assets/images/icon/examAnswerIcon/Agree.svg";
import { ReactComponent as Agree } from "../../../../../../assets/images/icon/examAnswerIcon/stronglyAgree.svg";
import { ReactComponent as Unsure } from "../../../../../../assets/images/icon/examAnswerIcon/Unsure.svg";
import { ReactComponent as StronglyDisagree } from "../../../../../../assets/images/icon/examAnswerIcon/StronglyDisagree.svg";
import { ReactComponent as Disagree } from "../../../../../../assets/images/icon/examAnswerIcon/Disagree.svg";
import { VerticalLeftOutlined, VerticalRightOutlined } from '@ant-design/icons';
import { rules } from './Rules';
import { snakeCaseString } from '../../../../../../config/Global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/app';

/**
 * Renders a form component for the interest assessment exam .
 *
 * @param {object} props - The props object containing the following properties:
 *   - steps: An array of steps for the exam.
 *   - current: The current step index.
 *   - setCurrent: A function to set the current step index.
 *   - getFirstSix: An array of the first six steps.
 *   - lastArrayData: The data of the last step.
 *   - btnLoading: A boolean indicating if the button is loading.
 *   - type: The type of the form (view or edit).
 * @return {JSX.Element} The rendered form component.
 */
const InterestAssessmentForm = (props: any) => {

    const { steps, current, setCurrent, getFirstSix, lastArrayData, btnLoading, type } = props;
    const initialStoreData: any = useSelector((state: RootState) => state.AUTH.initialData);

    const [options, setOption] = useState<any>();
    const svgIcons: any = {
        strongly_agree: <StronglyAgree />,
        agree: <Agree />,
        neutral: <Unsure />,
        disagree: <Disagree />,
        strongly_disagree: <StronglyDisagree />,
    };
    
    // Set interest assessment options type to display on form
    useEffect(() => {
        const initialData: any = sessionStorage.getItem("initialData")
        const initialDataJson = JSON.parse(initialData);
        if (initialDataJson) {
            setOption(initialDataJson?.interest_assessment_options_type)
        } else {
            setOption(initialStoreData?.interest_assessment_options_type)
        }
    }, [])

    return (
        <div className='examModal'>
            <div className='container'>
                <div className='breadcrumb'>
                    <BreadcrumbComponent key={1} items={InterestAssessmentBreadcrumb.path} />
                </div>
                <div className="interesQnaSection">
                    <div className='totalAns'>
                        <div className="totalAnsMain">
                            <div className='allAns'>
                                <span className='currentAns'>{props?.getFirstSix[0]?.index} - {lastArrayData?.index} </span> / <span className='totalLength'>50</span>
                            </div>
                            {/* <div className='time'>
                                {formatTime()}
                            </div> */}
                        </div>
                    </div>
                    <div className='ansListRadiobtn'>
                        <FormBox
                            layout="vertical"
                            autoComplete="off"
                            form={props.form}
                            id={props.id}
                            onFinish={props.nextButton}
                            className="userProfile commanDrawer"
                        // onChange={validateForm}
                        >
                            {type === "view" ? <Progress percent={100} strokeColor="#1DAB3B" /> :
                                <Progress percent={(current / 50) * 100} strokeColor="#1DAB3B" />
                            }
                            {getFirstSix.map((item: any, index: any) => {
                                return (
                                    <Row align={'middle'} key={index} className='radioGrpExam'>
                                        <Col xs={24} sm={24} md={12} xl={16} className='questions'>
                                            <div className="step_content"> <span className='currentAns'>Q.{item.index}</span> <span className='currentQuestion'>{item?.title}</span></div>

                                        </Col>

                                        <Col xs={24} sm={24} md={12} xl={8} className='answers'>
                                            <Form.Item name={item?.question_id}
                                                rules={props.type !== "view" ? rules.answer() : []}

                                            >
                                                <Radio.Group className='examRadioGrp' disabled={(steps.length > 36 && item.disabled || type === "view") ? true : false}>
                                                    <div className='radioGrpExamIcon'>
                                                        {options?.map((contentItem: any, index: number) => {
                                                            return (
                                                                <Radio value={contentItem.id} key={index} >
                                                                    <div className={`${snakeCaseString(contentItem.title)} image`} >
                                                                        <Tooltip placement="top" title={contentItem?.title} overlayClassName='examTooltip' >
                                                                            {svgIcons[snakeCaseString(contentItem.title).replace(" ", "")]}
                                                                        </Tooltip>
                                                                    </div>
                                                                </Radio>
                                                            )
                                                        })}
                                                    </div>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                )
                            })
                            }
                            <div className="logInBtn">
                                {current >= 1 &&
                                    <Button className='common_Button green_border_common_btn' onClick={() => { setCurrent(current - 6) }}><VerticalRightOutlined /> Previous </Button>}

                                {lastArrayData?.index === 50
                                    ? <Button className='common_Button green_border_common_btn' form={props.id} loading={btnLoading} htmlType='submit'>DONE</Button>
                                    : <Button className='common_Button green_border_common_btn' form={props.id} loading={btnLoading} htmlType='submit'>Next <VerticalLeftOutlined /></Button>
                                }
                            </div>
                        </FormBox>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default InterestAssessmentForm