import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../store/app";
import API_URL from "../../../../config/ApiUrl";
import api from "../../../../store/api";


interface CmsPageState {
    cmsPage: any,
    appLoading: boolean;
}
const initialState = {
    cmsPage: null,
    appLoading: true,
} as CmsPageState

const CmsPageSlice = createSlice({
    name: "CMSPAGESLICE",
    initialState,
    reducers: {
        setCmsPage: (state, action: PayloadAction<any>) => {
            state.cmsPage = action.payload
        },
        setAppLoading: (state, action: PayloadAction<boolean>) => {
            state.appLoading = action.payload;
        },
    }
})
export const { setCmsPage, setAppLoading } = CmsPageSlice.actions;

export const cmsPage = (slug: string): AppThunk<any> => async (dispatch) => {
    try {
        dispatch(setAppLoading(true));
        const response = await api.get(API_URL.CMS_DETAIL(slug))
        dispatch(setCmsPage(response.data.data));
        dispatch(setAppLoading(false));
        return Promise.resolve(response.data.data);
    } catch (error: any) {
        dispatch(setAppLoading(false));
        dispatch(setCmsPage(null));
        return Promise.reject(error.data);
    }
}

export default CmsPageSlice.reducer;