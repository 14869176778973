import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../store/app";
import api from "../../../../store/api";
import API_URL from "../../../../config/ApiUrl";
// import { apiUrls } from "./apiUrls";

interface HomeState {
  pageSettings: any;
  appLoading: boolean;
}

const initialState = {
  pageSettings: null,
  appLoading: true,
} as HomeState;

const HomeSlice = createSlice({
  name: "HOME",
  initialState,
  reducers: {
    setPageSettings: (state, action: PayloadAction<any>) => {
      state.pageSettings = action.payload;
    },
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
  },
});

export const { setPageSettings, setAppLoading } = HomeSlice.actions;

export const getStatus =
  (type: string): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(setAppLoading(true));
      const response = await api.get(API_URL.pageSettings(type));
      dispatch(setPageSettings(response.data.rows));
      dispatch(setAppLoading(false));
      return Promise.resolve(response.data.rows);
    } catch (error: any) {
      dispatch(setAppLoading(false));
      dispatch(setPageSettings([]));
      return Promise.reject(error.data);
    }
  };

export default HomeSlice.reducer;
