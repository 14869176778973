import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/app';
import { WebConstant } from '../webConstant/WebConstant';
import { useNavigate } from 'react-router-dom';
import "../style/logInRegisterHeader.less"
import { Button } from 'antd';

const LogInRegisterHeader = () => {
  const bannerContent = useSelector((state: RootState) => state.HOME?.pageSettings)
  const bannerData = bannerContent?.banner;
  
  const Navigate = useNavigate()
  const login = () => {
    Navigate("/login")
  }
  const register = () => {
    Navigate("/register")
  }
  return (
    <section className='LogInRegister'>
      <div className="container">
        <div className="logInRegisterBtnLogo">
          {bannerData?.banner_logo &&
            <div className="logoImg" onClick={() => Navigate("/")}>
              <img className="logo" src={WebConstant?.IMAGE_URL + bannerData.banner_logo} alt="logo" />
            </div>
          }
          <div className="loginBtn">
            <Button className="common_button green_border_common_btn" onClick={login}><span>Log In</span></Button>
            {(bannerContent?.options?.showRegister === "1") &&
              <Button className="common_button green_border_common_btn" onClick={register}><span>Register</span></Button>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default LogInRegisterHeader