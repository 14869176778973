import { Col, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import "../style/footer.less"
import { NavLink } from 'react-router-dom'

/**
 * Renders the footer section of the website.
 *
 * @return {JSX.Element} The rendered footer section.
 */
const Footer = () => {
    return (
        <section className='footer-section'>
            <div className='container'>
                <div className='footer-section-content'>
                    <Row gutter={30} align="middle" className='footer-section-row'>
                        <Col sm={24} md={24} lg={12} xxl={12} className='footer-setion_inner'>
                            <p className='footer-setion-copy'>© {moment().format("Y")} - IWNL ENERGY. All rights reserved.</p>
                        </Col>
                        <Col sm={24} md={24} lg={12} xxl={12}>
                            <div className='footer-setion-terms'>
                                <ul>
                                    <li >
                                        <NavLink to="https://www.iwnlenergy.com/#!/about" target='_blank'>
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/terms-of-use">
                                            TERMS & CONDITIONS
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/privacy-policy">
                                            PRIVACY POLICY
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default Footer