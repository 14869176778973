import { formRules } from "../../../../../config/validations/formRules";
import { validations } from "../../../../../config/validations/validations";


export const rules: any = {
	name: () => formRules.first_name(),
	email: (field?: string) => [
		validations.required.text(field),
		validations.email(field),
	],
	mobile: (field?: any) => [
		validations.pattern.phone,
		validations.required.text(field),
		validations.min.number(10),
		validations.max.number(12)
	],
	dob: (field?: any) => [
		validations.required.select(field)
	],
	gender: () => [
		validations.required.select()
	],
	race: () => [
		validations.required.select()
	],
	disabilities: () => [
		validations.required.select()
	],
	veteran_status: () => [
		validations.required.select()
	],
	location: () => [
		validations.required.text(),
		validations.pattern.numeric
		
	],
	zipCode: (field?: any) => [
		validations.required.select(field)
	],

};