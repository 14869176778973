import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import OtpInput from "react-otp-input"
import { rules } from './Rules'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../store/app'
import { assignErrorToInput } from '../../../../store/api'
import { forgotPassword, verifyOtp } from '../../../../store/AuthSlice'
import { useNavigate } from 'react-router-dom'

/**
 * Renders the Forgot Password component.
 *
 * @return {JSX.Element} The rendered ForgotPassword component.
 */
const ForgotPassword = () => {
    const [showOtp, setShowOtp] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch<any>();
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [otp, setOtp] = useState("");
    const [emailId, setEmailId] = useState("")

    const handleSubmit = (data: any) => {
        dispatch(forgotPassword(data))
            .then(() => {
                setEmailId(data.email)
                setShowOtp(true)
            })
            .catch((error: any) => assignErrorToInput(form, error?.STATUS))
    }

    // Send OTP on User Email Id for password reset
    const submitOtp = () => {
        const base64EncodedOtp = `${otp}:${emailId}`;
        const base64Encoded = btoa(base64EncodedOtp);

        if (base64Encoded) {
            dispatch(verifyOtp(base64Encoded))
                .then(() => {
                    navigate(`/reset/${base64Encoded}`)
                })
                .catch((error: any) => assignErrorToInput(form, error?.STATUS))
        }
    }

    return (
        <>
            <section className='webLoginSection webForgotPassword'>
                <div className='webLoginBox'>
                    <h2 className="subtitle" style={{minWidth:"395px"}}>Reset Password</h2>
                    <FormBox form={form} onFinish={handleSubmit}>
                        <Row>
                            <Col sm={24} xs={24} md={24} xxl={24}>
                                <InputBox.Text
                                    name="email"
                                    placeholder='Email Address'
                                    rules={rules.email()}
                                />
                            </Col>
                        </Row>
                        <div className='logInBtn'>
                            <Button className='log_in_button secondry_btn_log_page' htmlType="submit">
                                SEND OTP
                            </Button>
                        </div>
                    </FormBox>
                    {showOtp &&
                        <>
                            <h2 className="subtitle otpTitle">Enter OTP</h2>
                            <OtpInput
                                containerStyle="otpInput"
                                value={otp}
                                onChange={(otp: any) => setOtp(otp)}
                                numInputs={5}
                                renderInput={(props: any) => <input   {...props} />}

                            />

                            <div className='logInBtn'>
                                <Button className='log_in_button secondry_btn_log_page' onClick={submitOtp}>
                                    SUBMIT
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </section>
        </>
    )
}

export default ForgotPassword