import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/app'
import { Button } from 'antd'
import SimpleSlider from '../../CommonComponent/Slider'
import { contentMarkup } from '../../../../config/Global'

/**
 * Renders the Testimonial component for the home page that 
 * contains  slider for the testimonials.
 *
 * @return {JSX.Element} The rendered Testimonial component.
 */
const Testimonial = () => {
	const testimonial = useSelector((state: RootState) => state.HOME?.pageSettings)
	const testimonialData = testimonial?.testimonial
	return (
		<section className='testimonial'>
			<div className='container'>
				<div className='content_section'>
					{testimonialData?.subtitle &&
						<h4>
							{testimonialData.subtitle}
						</h4>
					}

					{testimonialData?.title &&
						<h2 className='heading'> {testimonialData.title}</h2>
					}
					{testimonialData?.description &&
						 <div dangerouslySetInnerHTML={contentMarkup(testimonialData.description)}></div>
					}
					{testimonialData?.main_button_title &&
						<a href={testimonialData?.main_button_link}>
							<Button className="common_button green_border_common_btn"><span>{testimonialData.main_button_title}</span></Button>
						</a>
					}
				</div>

				{testimonialData?.items && <div className='sliderSection'>


					<SimpleSlider title={"Connect With Other Members"} slideData={testimonialData.items} />

				</div>
				}
			</div>
		</section>
	)
}

export default Testimonial